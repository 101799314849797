<div class="itdoc-page ">
  <div class="row">
    <div class="col-md-11">
      <div class="title-container">
        <h2>{{"Nuovo template"}}</h2>         
      </div> 
      <p>Crea il tuo nuovo template</p>
    </div>
    <div class="col-md-1 text-end">
      <button mat-icon-button [mat-dialog-close]="null">
        <span class="material-icons icon-close" >
          close
        </span>
      </button>
    </div>
  </div>
  <mat-dialog-content>
   <app-form-builder></app-form-builder>
  </mat-dialog-content>

  <div mat-dialog-actions align="end" class="dialog-buttons-container" >
    <button mat-button class="it-doc-btn-primary">
      <span class="button-text">{{navigatorService.Dictionary?.Next}}</span>
    </button>
    <button mat-button class="it-doc-btn-primary" >
      <span class="button-text">{{navigatorService.Dictionary?.Back}}</span>
    </button>
  </div>
</div>
