import { Component, OnInit, signal } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { Column, COMPONENTTYPE, Form, FormComponent, Row, SECTION } from './Form';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit {
  Form!: Form;
  Components: Array<FormComponent>;
  readonly panelOpenState = signal(false);
  LayoutSelected: number = 1;
  RowSelected: Row = null;
  SECTION = SECTION;
  COMPONENTTYPE = COMPONENTTYPE;
  ngOnInit(): void {
    this.Load();
  }

  Load() {
    this.Form = new Form();
    this.LayoutSelected = 1;
    this.RowSelected = this.Form.LastRow();
    this.Components = this.Form.GetComponents();
  }
  //#region FormBuilder
  DropRow(event: CdkDragDrop<Row[]>) {
    moveItemInArray(this.Form.Rows, event.previousIndex, event.currentIndex);
    this.Form.DropRow(event.previousIndex, event.currentIndex);
  }

  // DropColumn(event: CdkDragDrop<Column[]>, row: Row | null = null) {
  //   if (event.previousContainer === event.container && row != null) {
  //     moveItemInArray(row.Columns, event.previousIndex, event.currentIndex);
  //     row.DropColumn(event.previousIndex, event.currentIndex);
  //   } else {
  //     transferArrayItem(
  //       event.previousContainer.data,
  //       event.container.data,
  //       event.previousIndex,
  //       event.currentIndex,
  //     );
  //   }
  // }

  DropComponent(event: CdkDragDrop<FormComponent[]>, column: Column) {
    if (event.previousContainer === event.container && column != null) {
      moveItemInArray(column.Components, event.previousIndex, event.currentIndex);
      column.MoveComponent(event.previousIndex, event.currentIndex);
    } else {
      const itemCopy = this.Form.CloneComponent(event.item.data);
      column.Components.splice(event.currentIndex, 0, itemCopy);
      column.DropComponent(event.currentIndex, itemCopy);
    }
  }

  AddRow() {
    this.Form.AddRow();
    this.Form.SetColumnsGuids();
    this.RowSelected = this.Form.LastRow();
  }

  AddColumn(row: Row) {
    row.AddColumn();
    this.Form.SetColumnsGuids();
  }

  DeleteRow(row: Row) {
    this.Form.DeleteRow(row.Index);
    this.Form.SetColumnsGuids();
    this.RowSelected = this.Form.LastRow();
    console.log(this.RowSelected);
  }

  DeleteColumn(row: Row, column: Column) {
    row.DeleteColumn(column.Index);
    this.Form.SetColumnsGuids();
  }

  public SelectRow(row: Row) {
    this.RowSelected = row;
    this.LayoutSelected = row.Columns.length;
  }
  print() {
    console.log(this.Form);
  }
  //#endregion form builder

  //#region Widget
  public SetLayout(columns: number) {
    if (this.RowSelected) {
      this.LayoutSelected = columns;
      this.RowSelected.SetColumns(columns)
      this.Form.SetColumnsGuids();
    }
  }
  //#endregion Widget

  public Save() {
    console.log(JSON.stringify(this.Form.Rows));
  }
}
