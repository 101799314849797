<!-- type text -->
<mat-form-field class="form-field" *ngIf="Type === 'text'">
  <mat-label class="placeholder">{{Placeholder}}</mat-label>
  <div *ngIf="!Required && !Disabled">
    <input class="input-text" matInput type="{{Type}}" [(ngModel)]="DataValue[Property]" maxlength="{{Maxlength}}" pattern="{{Regex}}"  autocomplete="off">
  </div>
  <div *ngIf="Required && !Disabled">
    <input class="input-text" matInput type="{{Type}}" [(ngModel)]="DataValue[Property]" maxlength="{{Maxlength}}" pattern="{{Regex}}"
    autocomplete="off" required>
  </div>
  <div *ngIf="Disabled">
    <input class="input-text" matInput type="{{Type}}" [(ngModel)]="DataValue[Property]" maxlength="{{Maxlength}}" pattern="{{Regex}}"  disabled >
  </div>
  <button *ngIf="DataValue[Property] && !Disabled" matSuffix mat-icon-button aria-label="Clear"
    (click)="DataValue[Property]=null" autocomplete="off">
    <mat-icon>close</mat-icon>
  </button>
  <mat-hint align="start" class="description" *ngIf="Type === 'text'">{{Description}}</mat-hint>
</mat-form-field>
<!-- type password -->
<mat-form-field class="form-field" *ngIf="Type === 'password'">
  <mat-label class="placeholder">{{Placeholder}}</mat-label>
  <div *ngIf="Required">
    <input class="input-text" matInput type="{{Type}}" [(ngModel)]="DataValue[Property]" maxlength="{{Maxlength}}" pattern="{{Regex}}"
    autocomplete="off" required>
  </div>
  <button *ngIf="DataValue[Property] && !Disabled" matSuffix mat-icon-button aria-label="Clear"
    (click)="DataValue[Property]=null" autocomplete="off">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>


<!-- type integer -->
<mat-form-field class="form-field" *ngIf="Type === 'Integer'">
  <mat-label class="placeholder">{{Placeholder}}</mat-label>
  <div *ngIf="!Required && !Disabled">
    <input (keypress)='ValidateNumberInput($event)' (paste)="onPasteNumber($event)" class="input-text" matInput
    type="text" [(ngModel)]="DataValue[Property]" maxlength="{{Maxlength}}" pattern="{{Regex}}" autocomplete="off">
  </div>
  <div *ngIf="Required && !Disabled">
    <input (keypress)='ValidateNumberInput($event)' (paste)="onPasteNumber($event)" class="input-text"
    matInput type="text" [(ngModel)]="DataValue[Property]" maxlength="{{Maxlength}}" pattern="{{Regex}}" autocomplete="off" required>
  </div>
  <div *ngIf="Disabled">
    <input  class="input-text" matInput type="text" [(ngModel)]="DataValue[Property]" maxlength="{{Maxlength}}" pattern="{{Regex}}" disabled >
  </div>
</mat-form-field>

<!-- type CF -->
<mat-form-field [ngClass]="IsValid?'':'error'" class="form-field" *ngIf="Type === 'CF'">
  <mat-label class="placeholder">{{Placeholder}}</mat-label>
  <div *ngIf="!Required && !Disabled">
    <input matInput (keypress)='ValidateCFInput($event)' (paste)="onPasteCF($event)" (mouseleave)="ValidateCF($event)" class="input-text"
     type="text" [(ngModel)]="DataValue[Property]" maxlength="{{Maxlength}}" pattern="{{Regex}}"  autocomplete="off">
  </div>
  <div *ngIf="Required && !Disabled">
    <input  (keypress)='ValidateCFInput($event)' (paste)="onPasteCF($event)"  class="input-text" matInput type="text" [(ngModel)]="DataValue[Property]" maxlength="{{Maxlength}}" pattern="{{Regex}}"
    autocomplete="off" required>
  </div>
  <div *ngIf="Disabled">
    <input  class="input-text" matInput type="text" [(ngModel)]="DataValue[Property]" maxlength="{{Maxlength}}" pattern="{{Regex}}"  disabled >
  </div>
  <button *ngIf="DataValue[Property] && !Disabled" matSuffix mat-icon-button aria-label="Clear"
    (click)="DataValue[Property]=null" autocomplete="off">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>

<!-- type PIVA -->
<mat-form-field  class="form-field" *ngIf="Type === 'PIVA'">
  <mat-label class="placeholder">{{Placeholder}}</mat-label>
  <div *ngIf="!Required && !Disabled">
    <input matInput  class="input-text" (keypress)='ValidateNumberInput($event)' (paste)="onPasteNumber($event)"
     type="text" [(ngModel)]="DataValue[Property]" maxlength="{{Maxlength}}" pattern="{{Regex}}"  autocomplete="off">
  </div>
  <div *ngIf="Required && !Disabled">
    <input  class="input-text" matInput (keypress)='ValidateNumberInput($event)' (paste)="onPasteNumber($event)"
     type="text" [(ngModel)]="DataValue[Property]" maxlength="{{Maxlength}}" pattern="{{Regex}}"
    autocomplete="off" required>
  </div>
  <div *ngIf="Disabled">
    <input  class="input-text" matInput type="text" [(ngModel)]="DataValue[Property]" maxlength="{{Maxlength}}" pattern="{{Regex}}"  disabled >
  </div>
  <button *ngIf="DataValue[Property] && !Disabled" matSuffix mat-icon-button aria-label="Clear"
    (click)="DataValue[Property]=null" autocomplete="off">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>

<!-- type PIVACF -->
<mat-form-field *ngIf="Type === 'PIVACF'" [ngClass]="IsValid?'':'error'" class="form-field">
  <mat-label class="placeholder">{{Placeholder}}</mat-label>
  <div *ngIf="!Required && !Disabled">
    <input matInput class="input-text" (keypress)='ValidateCFInput($event)' (paste)="onPastePIVACF($event)"
      (mouseleave)="ValidatePIVACF($event)" type="text" [(ngModel)]="DataValue[Property]" maxlength="{{Maxlength}}"
      pattern="{{Regex}}" autocomplete="off">
  </div>
  <div *ngIf="Required && !Disabled">
    <input class="input-text" matInput (keypress)='ValidateCFInput($event)' (paste)="onPastePIVACF($event)"
      (mouseleave)="ValidatePIVACF($event)" type="text" [(ngModel)]="DataValue[Property]" maxlength="{{Maxlength}}"
      pattern="{{Regex}}" autocomplete="off" required>
  </div>
  <div *ngIf="Disabled">
    <input class="input-text" matInput type="text" [(ngModel)]="DataValue[Property]" maxlength="{{Maxlength}}"
      pattern="{{Regex}}" disabled>
  </div>
  <button *ngIf="DataValue[Property] && !Disabled" matSuffix mat-icon-button aria-label="Clear"
    (click)="DataValue[Property]=null" autocomplete="off">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
<!-- type text-area -->
<mat-form-field class="form-field" *ngIf="Type === 'text-area'" appearance="outline">
  <mat-label class="placeholder">{{Placeholder}}</mat-label>
  <div *ngIf="!Required">
    <textarea matInput class="input-text" [(ngModel)]="DataValue[Property]" rows="4"
      maxlength="{{Maxlength}}"></textarea>
  </div>
  <div *ngIf="Required">
    <textarea matInput class="input-text" [(ngModel)]="DataValue[Property]" rows="4" maxlength="{{Maxlength}}"
      required></textarea>
  </div>
</mat-form-field>
<!-- type text-button -->
<mat-form-field class="form-field" *ngIf="Type === 'text-button'">
  <mat-label class="placeholder">{{Placeholder}}</mat-label>
  <div *ngIf="!Required && !Disabled">
    <input class="input-text" matInput type="{{Type}}" [(ngModel)]="DataValue[Property]" maxlength="{{Maxlength}}" pattern="{{Regex}}">
  </div>
  <div *ngIf="Required && !Disabled">
    <input class="input-text" matInput type="{{Type}}" [(ngModel)]="DataValue[Property]" maxlength="{{Maxlength}}"  pattern="{{Regex}}" required >
  </div>
  <div *ngIf="Disabled">
    <input class="input-text" matInput type="{{Type}}" [(ngModel)]="DataValue[Property]" maxlength="{{Maxlength}}" pattern="{{Regex}}" disabled>
  </div>
  <!-- <button mat-button *ngIf="DataValue[Property] && !Disabled" matSuffix mat-icon-button aria-label="Clear"
    (click)="DataValue[Property]=null" autocomplete="off">
    <mat-icon>close</mat-icon>
  </button> -->
  <mat-icon *ngIf="!Disabled" matSuffix class="pointer" (click)="AddButton()">add</mat-icon>
</mat-form-field>
