<div class="component-container">
  <span class="material-icons icon-drag  icon-align-center">
    drag_indicator
  </span>
  <!-- type text -->
  <mat-form-field class="form-field" *ngIf="Type === 'text'">
    <mat-label class="placeholder">{{"Test"}}</mat-label>
    <div *ngIf="!Required && !Disabled">
      <input class="input-text" matInput type="{{Type}}" maxlength="{{Maxlength}}"
        pattern="{{Regex}}" autocomplete="off">
    </div>
    <div *ngIf="Required && !Disabled">
      <input class="input-text" matInput type="{{Type}}" maxlength="{{Maxlength}}"
        pattern="{{Regex}}" autocomplete="off" required>
    </div>
    <div *ngIf="Disabled">
      <input class="input-text" matInput type="{{Type}}" maxlength="{{Maxlength}}"
        pattern="{{Regex}}" disabled>
    </div>   
    <mat-hint align="start" class="description" *ngIf="Type === 'text'">{{Description}}</mat-hint>
  </mat-form-field>
 
  <span class="material-icons  icon icon-align-center">
    delete
  </span>
</div>