import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../data.service';
import { NavigatorService } from '../../../../navigator.services';
import { AuthService } from '../../../../auth.service';
import { CommonService } from '../../../../common.service';
import { PageAction } from '../../../doc.configuration';
import { TemplateComponent } from '../../dialog-menu/template/template.component';

@Component({
  selector: 'app-template-view',
  templateUrl: './template-view.component.html',
  styleUrls: ['./template-view.component.scss']
})
export class TemplateViewComponent implements OnInit{
  constructor(
    dataService: DataService,
    public navigatorService: NavigatorService,
    public authenticationService: AuthService,
    public commonService: CommonService,
    
  ) {
  }

  ngOnInit(): void {
  } 

  public Create(){
    this.navigatorService.PageAction = PageAction.New;
    this.navigatorService.ShowDialog(TemplateComponent,null, '95vw', '90vh', '200px',
      (response) => {

      },'95vw'
    );
  }
}
