import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AutocompleteFilterUiComponent } from '../documentary/custom-components/autocomplete-ui/autocomplete-ui.component';
import { InputComponent } from '../documentary/custom-components/input/input.component';
import { SelectComponent } from '../documentary/custom-components/select/select.component';
import { DashboardComponent } from '../documentary/pages/dashboard/dashboard.component';
import { DialogMenuComponent } from '../documentary/pages/dialog-menu/dialog-menu.component';
import { DocumentComponent } from '../documentary/pages/dialog-menu/document/document.component';
import { DossiersComponent } from '../documentary/pages/dialog-menu/dossiers/dossiers.component';
import { TipologyComponent } from '../documentary/pages/dialog-menu/tipology/tipology.component';
import { ContainerComponent } from './container.component';
import { FolderComponent } from '../documentary/pages/dialog-menu/folder/folder.component';
import { SnackbarComponent } from '../documentary/custom-components/snackbar/snackbar.component';
import { UploadComponent } from '../documentary/custom-components/upload/upload.component';
import { OrganizationUnitViewComponent } from '../documentary/pages/view/organization-unit-view/organization-unit-view.component';
import { OrganizationUnitComponent } from '../documentary/pages/dialog-menu/organization-unit/organization-unit.component';
import { RangepickerComponent } from '../documentary/custom-components/rangepicker/rangepicker.component';
import { TreeComponent } from '../documentary/custom-components/tree/tree.component';
import { PickerComponent } from '../documentary/custom-components/picker/picker.component';
import { TipologyViewComponent } from '../documentary/pages/view/tipology-view/tipology-view.component';
import { DocumentViewComponent } from '../documentary/pages/view/document-view/document-view.component';
import { DossierViewComponent } from '../documentary/pages/view/dossier-view/dossier-view.component';
import { SearchComponent } from '../documentary/custom-components/search/search.component';
import { TrashedViewComponent } from '../documentary/pages/view/trashed-view/trashed-view.component';
import { AddToDossierComponent } from '../documentary/pages/dialog-menu/add-to-dossier/add-to-dossier.component';
import { FilterDocumentComponent } from '../documentary/pages/dialog-menu/filter-document/filter-document.component';
import { DropdownTreeComponent } from '../documentary/custom-components/dropdown-tree/dropdown-tree.component';
import { MultiselectDropdownTreeComponent } from '../documentary/custom-components/multiselect-dropdown-tree/multiselect-dropdown-tree.component';
import { MediaViewerComponent } from '../documentary/pages/media-viewer/media-viewer.component';
import { NoRightClickDirective } from '../documentary/pages/media-viewer/no-right-click.directive';
import { GenericConfirmDialogComponent } from '../documentary/pages/dialog-menu/generic-confirm-dialog/generic-confirm-dialog.component';
import { FilterDossierComponent } from '../documentary/pages/dialog-menu/filter-dossier/filter-dossier.component';
import { Pages as DocPages } from '../documentary/doc.configuration';
import { AppMaterialModule } from '../app-material.module';
import { NavigatorService } from '../navigator.services';
import { DataService } from '../data.service';
import { DatePipe } from '@angular/common';
import { CommonService } from '../common.service';
import { HeaderComponent } from './header/header.component';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { ChipsMultiselectComponent } from '../documentary/custom-components/chips-multiselect/chips-multiselect.component';
import { TipologyDetailComponent } from '../documentary/pages/view/tipology-view/tipology-detail/tipology-detail.component';
import { DossierDetailComponent } from '../documentary/pages/view/dossier-view/dossier-detail/dossier-detail.component';
import { CardComponent } from '../documentary/pages/dashboard/card/card.component';
import { BaseChartDirective } from 'ng2-charts';
import { DoughnutChartComponent } from '../documentary/custom-components/doughnut-chart/doughnut-chart.component';
import { ProgressBarChartComponent } from '../documentary/custom-components/progress-bar-chart/progress-bar-chart.component';
import { DeliberationsCardComponent } from '../documentary/pages/dashboard/deliberations-card/deliberations-card.component';
import { ProtocolsCardComponent } from '../documentary/pages/dashboard/protocols-card/protocols-card.component';
import { ActivitiesCardComponent } from '../documentary/pages/dashboard/activities-card/activities-card.component';
import { WorkflowViewComponent } from '../workflow/pages/view/workflow-view/workflow-view.component';
import { NewWorkflowComponent } from '../workflow/pages/dialog/new-workflow/new-workflow.component';
import { ArchiveDocumentViewComponent } from '../documentary/pages/view/archive-document-view/archive-document-view.component';
import { WorkflowPreviewComponent } from '../documentary/pages/view/document-view/workflow-preview/workflow-preview.component';
import { ProtocolsViewComponent } from '../documentary/pages/view/protocols-view/protocols-view.component';
import { SettingsComponent } from '../settings/settings.component';
import { UserComponent } from '../settings/dialog/user/user.component';
import { DossierArchivedViewComponent } from '../documentary/pages/view/dossier-archived-view/dossier-archived-view.component';
import { UoTrashedViewComponent } from '../documentary/pages/view/uo-trashed-view/uo-trashed-view.component';
import { AcronymPipe } from '../pipes/acronym.pipe';
import { DialogDashboardComponent } from '../documentary/pages/dashboard/dialog-dashboard/dialog-dashboard.component';
import { SignatureAccountComponent } from '../settings/dialog/signature-account/signature-account.component';
import { SignatureComponent } from '../documentary/pages/dialog-menu/signature/signature.component';
import { AttachedDocumentComponent } from '../documentary/pages/dialog-menu/attached-document/attached-document.component';
import { DragDropDirective } from '../documentary/pages/dialog-menu/attached-document/drag-drop.directive';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { ProfileInfoComponent } from '../documentary/pages/dialog-menu/profile-info/profile-info.component';
import { ResizableDraggableComponent } from '../documentary/pages/dialog-menu/signature/resizable-draggable/resizable-draggable.component';
import { NotificationHistoryComponent } from './header/notification-history/notification-history.component';
import { VerifySignatureComponent } from '../documentary/pages/dialog-menu/signature/verify-signature/verify-signature.component';
import { EncryptComponent } from '../documentary/pages/dialog-menu/encrypt/encrypt.component';
import { PagerComponent } from '../documentary/custom-components/pager/pager.component';
import { WizardComponent } from '../documentary/custom-components/wizard/wizard.component';
import { DocumentClassComponent } from '../documentary/pages/dialog-menu/document-class/document-class.component';
import { DocumentListComponent } from '../documentary/pages/dialog-menu/document-list/document-list.component';
import { ConservationViewComponent } from '../documentary/pages/view/conservation-view/conservation-view.component';
import { PdaViewComponent } from '../documentary/pages/view/conservation-view/pda-view/pda-view.component';
import { ConservationLoginComponent } from '../documentary/pages/dialog-menu/conservation-login/conservation-login.component';
import { PdaDetailComponent } from '../documentary/pages/view/conservation-view/pda-detail/pda-detail.component';
import { PdaNewComponent } from '../documentary/pages/view/conservation-view/pda-new/pda-new.component';
import { MessageComponent } from '../documentary/pages/dialog-menu/message/message.component';
import { UploadquequeComponent } from '../documentary/pages/dialog-menu/uploadqueque/uploadqueque.component';
import { UploadService } from '../upload.service';
import { ProgressBarComponent } from '../documentary/custom-components/progress-bar/progress-bar.component';
import { ProgressBarPopupComponent } from '../documentary/pages/dialog-menu/progress-bar-popup/progress-bar-popup.component';
import { AuthGuardService } from '../auth-guard.service ';
import { MyDocumentsViewComponent } from '../documentary/pages/view/my-documents-view/my-documents-view.component';
import { MyDocumentComponent } from '../documentary/pages/dialog-menu/my-document/my-document.component';
import { MyTrashedViewComponent } from '../documentary/pages/view/my-trashed-view/my-trashed-view.component';
import { SidebarProfileComponent } from './sidebar-profile/sidebar-profile.component';
import { FormBuilderComponent } from '../documentary/pages/dialog-menu/template/form-builder/form-builder.component';
import { FormDateComponent } from '../documentary/pages/dialog-menu/template/form-builder/components/form-date/form-date.component';
import { FormInputComponent } from '../documentary/pages/dialog-menu/template/form-builder/components/form-input/form-input.component';
import { FormLinkComponent } from '../documentary/pages/dialog-menu/template/form-builder/components/form-link/form-link.component';
import { TemplateViewComponent } from '../documentary/pages/view/template-view/template-view.component';
import { TemplateComponent } from '../documentary/pages/dialog-menu/template/template.component';

export const appRoutes: Routes = [
  {
    path: 'v1',
    component: ContainerComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: DocPages.Dashboard,
        component: DashboardComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: DocPages.ViewUO,
        component: OrganizationUnitViewComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: DocPages.ViewTipology,
        component: TipologyViewComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: DocPages.Documents,
        component: DocumentViewComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: DocPages.MyDocuments,
        component: MyDocumentsViewComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: DocPages.ViewDossiers,
        component: DossierViewComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: DocPages.ViewDossiersArchived,
        component: DossierArchivedViewComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: DocPages.FilesDelete,
        component: TrashedViewComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: DocPages.ViewWorkflow,
        component: WorkflowViewComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: DocPages.ViewArchive,
        component: ArchiveDocumentViewComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: DocPages.ViewProtocols,
        component: ProtocolsViewComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: DocPages.Settings,
        component: SettingsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: DocPages.UserProfile,
        component: UserProfileComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: DocPages.UoDelete,
        component: UoTrashedViewComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: DocPages.Conservation,
        component: ConservationViewComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: DocPages.ViewPda,
        component: PdaViewComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: DocPages.DetailPda,
        component: PdaDetailComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: DocPages.Pda,
        component: PdaNewComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: DocPages.TemplateView,
        component: TemplateViewComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },
];

@NgModule({
  imports: [
    AppMaterialModule,
    BaseChartDirective,
    RouterModule.forChild(appRoutes),
  ],
  declarations: [
    ContainerComponent,
    DashboardComponent,
    DocumentComponent,
    DossiersComponent,
    InputComponent,
    SearchComponent,
    AutocompleteFilterUiComponent,
    SelectComponent,
    TipologyComponent,
    DialogMenuComponent,
    FolderComponent,
    SnackbarComponent,
    UploadComponent,
    OrganizationUnitViewComponent,
    OrganizationUnitComponent,
    RangepickerComponent,
    TreeComponent,
    PickerComponent,
    TipologyViewComponent,
    DocumentViewComponent,
    MyDocumentsViewComponent,
    MyDocumentComponent,
    DossierViewComponent,
    TrashedViewComponent,
    AddToDossierComponent,
    FilterDocumentComponent,
    DropdownTreeComponent,
    MultiselectDropdownTreeComponent,
    MediaViewerComponent,
    GenericConfirmDialogComponent,
    NoRightClickDirective,
    FilterDossierComponent,
    HeaderComponent,
    SidebarMenuComponent,
    ChipsMultiselectComponent,
    TipologyDetailComponent,
    DossierDetailComponent,
    CardComponent,
    DoughnutChartComponent,
    ProgressBarChartComponent,
    DeliberationsCardComponent,
    ProtocolsCardComponent,
    ActivitiesCardComponent,
    WorkflowViewComponent,
    NewWorkflowComponent,
    ArchiveDocumentViewComponent,
    WorkflowViewComponent,
    WorkflowPreviewComponent,
    ProtocolsViewComponent,
    SettingsComponent,
    UserComponent,
    DossierArchivedViewComponent,
    UoTrashedViewComponent,
    AcronymPipe,
    DialogDashboardComponent,
    SignatureAccountComponent,
    SignatureComponent,
    AttachedDocumentComponent,
    DragDropDirective,
    UserProfileComponent,
    ProfileInfoComponent,
    ResizableDraggableComponent,
    NotificationHistoryComponent,
    VerifySignatureComponent,
    EncryptComponent,
    PagerComponent,
    WizardComponent,
    ConservationViewComponent,
    PdaViewComponent,
    DocumentClassComponent,
    DocumentListComponent,
    ConservationLoginComponent,
    PdaDetailComponent,
    PdaNewComponent,
    MessageComponent,
    UploadquequeComponent,
    ProgressBarComponent,
    ProgressBarPopupComponent,
    MyTrashedViewComponent,
    SidebarProfileComponent,
    TemplateViewComponent,
    TemplateComponent,
    FormBuilderComponent,
    FormDateComponent,
    FormInputComponent,
    FormLinkComponent
  ],
  providers: [
    NavigatorService,
    DataService,
    DatePipe,
    CommonService,
    UploadService,
  ],

  exports: [RouterModule],
})
export class ContainerModule { }
