<div class="itdoc-page itdoc-app-container-box">
  <div class="row m-0">
    <div class="col-md-12 p-0">
      <div class="user-menu-container d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <img
            *ngIf="authenticationService?.CurrentUser?.Avatar"
            class="profile-picture d-inline"
            src="data:image/png;base64,{{
              authenticationService?.CurrentUser?.Avatar
            }}"
            alt="User Avatar"
          />
          <span class="user-name-txt d-inline ml-2">{{
            authenticationService?.CurrentUser?.Displayname
          }}</span>
        </div>
        <!-- Edit Button -->
        <ng-container *ngIf="!isEditing ">
          <button mat-button class="it-doc-btn-primary" (click)="toggleEdit(1)">
            <img
              class="clickable-icon"
              src="../../../../../assets/icon/edit.svg"
            />
            <span class="button-text">{{
              navigatorService.Dictionary?.Edit
            }}</span>
          </button>
        </ng-container>
      </div>
    </div>

    <div class="row m-0">
      <div class="col-md-12 p-0">
        <div class="title-header-container">
          <div class="title-container">
            <div class="grid-container">
              <div class="grid-column" (click)="Load(1)" [ngClass]="Tab === 1 ? 'tab-selected' : ''">
                <span class="grid-column-title">
                  {{navigatorService.Dictionary?.Profile}}
                </span>
              </div>
              <div
                class="grid-column text-center"
                (click)="Load(2)"
                [ngClass]="Tab === 2 ? 'tab-selected' : ''"
              >
                <span class="grid-column-title">{{
                  navigatorService.Dictionary?.Roles
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Profile Info -->
    <div class="row m-0" *ngIf="Tab === 1">
      <div class="col-md-12 p-0">
        <div class="row-info">
          <div class="row">
            <div class="col-md-4">
              <p class="field-name">{{ navigatorService.Dictionary?.Name }}</p>
              <ng-container *ngIf="isEditing; else viewName">
                <div class="profileField">
                  <input
                    matInput
                    class="form-control"
                    [(ngModel)]="Dto.Name"
                  />
                </div>
                <span class="error" *ngIf="ErrorMessageName">{{ ErrorMessageName }}</span>
              </ng-container>
              <ng-template #viewName>
                <p class="field-value">
                  {{ authenticationService.CurrentUser.Name || "-" }}
                </p>
              </ng-template>
            </div>

            <div class="col-md-4">
              <p class="field-name">
                {{ navigatorService.Dictionary?.Surname }}
              </p>
              <ng-container *ngIf="isEditing; else viewSurname">
                <div class="profileField">
                  <input
                    matInput
                    class="form-control"
                    [(ngModel)]="Dto.Surname"
                  />
                </div>
                <span class="error" *ngIf="ErrorMessageSurname">{{ ErrorMessageSurname }}</span>
              </ng-container>
              <ng-template #viewSurname>
                <p class="field-value">
                  {{ authenticationService.CurrentUser.Surname || "-" }}
                </p>
              </ng-template>
            </div>

            <div class="col-md-4">
              <p class="field-name">{{ navigatorService.Dictionary?.Birthdate }}</p>
              <ng-container *ngIf="isEditing; else viewBirthdate">
                <div class="profileField d-flex align-items-center">
                  <input
                    matInput
                    [matDatepicker]="picker"
                    [(ngModel)]="Dto.Birthdate"
                    class="form-control col-md-6"
                    style="height: 36px;"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                    aria-label="Open datepicker"
                    class="custom-datepicker-toggle col-md-4 d-flex align-items-center"
                    style="height: 36px;"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker [dateClass]="custom-picker" ></mat-datepicker>
                </div>
              </ng-container>
              <ng-template #viewBirthdate>
                <p class="field-value birthdate-field">
                  {{ authenticationService.CurrentUser.Birthdate ? (authenticationService.CurrentUser.Birthdate | date : "dd/MM/yyyy") : "-" }}
                </p>
              </ng-template>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <p class="field-name">{{ navigatorService.Dictionary?.UsernameEmail }}</p>
              <ng-container *ngIf="isEditing; else viewEmail">
                <div class="profileField">
                  <input
                    matInput
                    class="form-control"
                    [(ngModel)]="Dto.Email"
                    (ngModelChange)="ErrorMessageEmail()"
                    [disabled]="true"
                  />
                </div>
                <span class="error" *ngIf="ErrorMessageEmail">{{ ErrorMessageEmail }}</span>
              </ng-container>
              <ng-template #viewEmail>
                <p class="field-value">
                  {{ authenticationService.CurrentUser.Email || "-" }}
                </p>
              </ng-template>
            </div>

            <div class="col-md-4">
              <p class="field-name">{{ navigatorService.Dictionary?.Phone }}</p>
              <ng-container *ngIf="isEditing; else viewPhone">
                <div class="profileField">
                  <input
                    matInput
                    class="form-control"
                    [(ngModel)]="Dto.Phone"
                  />
                </div>
                <span class="error" *ngIf="ErrorMessagePhone">{{ErrorMessagePhone}}</span>
              </ng-container>
              <ng-template #viewPhone>
                <p class="field-value">
                  {{ authenticationService.CurrentUser.Phone || "-" }}
                </p>
              </ng-template>
            </div>

            <div class="col-md-4">
              <p class="field-name">
                {{ navigatorService.Dictionary?.Password }}
              </p>
              <ng-container *ngIf="isEditing; else viewPassword">
                <div class="profileField">
                  <div class="fieldPassword">
                    <div class="input-container">
                      <input
                        matInput
                        class="form-control"
                        [type]="hide ? 'password' : 'text'"
                        placeholder="******"
                        [(ngModel)]="Dto.Password"
                      />
                      <label
                      class="hidePassword"
                      mat-icon-button matSuffix
                      (click)="hide = !hide"
                      [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide">
                        <img class="icon"
                        [src]="hide ? '../../../../assets/icon/eye.svg' : '../../../../assets/icon/eye-slash.svg'"
                        style="font-size: 18px;">
                      </label>
                    </div>
                  </div>
                </div>
                <span class="error" *ngIf="ErrorMessagePassword">{{ErrorMessagePassword}}</span>
              </ng-container>
              <ng-template #viewPassword>
                <p class="field-value">******</p>
              </ng-template>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <p class="field-name">
                {{ navigatorService.Dictionary?.Language }}
              </p>
              <ng-container *ngIf="isEditing; else viewLanguage">
                <div class="custom-select-wrapper">
                  <mat-select #languageSelect class= "custom-select" [(value)]="Dto.Language">
                    <mat-option *ngFor="let lang of Languages" [value]="lang.Value">
                      {{ lang.DisplayValue }}
                    </mat-option>
                  </mat-select>
                  <img matSuffix src="../../../../assets/icon/select-arrow.svg" class="custom-arrow-icon"
                  (click)="languageSelect.open()"
                  alt="Open select">
                </div>
              </ng-container>
              <ng-template #viewLanguage>
                <p class="field-value">
                  {{
                    commonService.GetLanguage(
                      authenticationService.CurrentUser.Language,
                      navigatorService.Dictionary
                    )
                  }}
                </p>
                <span class="error" *ngIf="ErrorMessageLanguage">{{ErrorMessageLanguage}}</span>
              </ng-template>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <p class="field-name">
                {{ navigatorService.Dictionary?.EncryptionPassword }}
              </p>
              <ng-container *ngIf="isEditing; else viewPassword">
                <div class="profileField">
                  <div class="fieldPassword">
                    <div class="input-container">
                      <input
                        matInput
                        class="form-control"
                        [type]="hide ? 'password' : 'text'"
                        placeholder="********"
                        [(ngModel)]="Dto.EncryptionPassword"
                      />
                      <label
                      class="hidePassword"
                      mat-icon-button matSuffix
                      (click)="hide = !hide"
                      [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide">
                      </label>
                    </div>
                  </div>
                </div>
                <span class="error" *ngIf="ErrorMessageEncryptionPassword">{{ErrorMessagePassword}}</span>
              </ng-container>
              <ng-template #viewPassword>
                <p class="field-value">******</p>
              </ng-template>
            </div>
          </div>


        </div>
      </div>
    </div>

    <!-- Save/Cancel Buttons when editing -->
    <div mat-dialog-actions *ngIf="isEditing" class="dialog-buttons-container">
        <button mat-button class="it-doc-btn-cancel" style="margin-inline-end: auto;" (click)="cancelEdit()">
          <span class="button-text">{{
            navigatorService.Dictionary?.Cancel
          }}</span>
        </button>
        <button mat-button class="it-doc-btn-primary" (click)="Save()">
          <span class="button-text">{{ navigatorService.Dictionary?.Save }}</span>
        </button>
    </div>
  </div>

  <div *ngIf="Tab === 2">
    <div class="row" style="height: 100%">
      <div class="col-md-12">
        <div class="form mt-3">
          <div
            *ngIf="UOModel?.Dtos?.length > 0"
            class="row w-100 table-uo-header"
          >
            <div class="it-doc-table-container">
              <table
                mat-table
                [dataSource]="UODataSource"
                matSort
                *ngIf="navigatorService.Dictionary"
              >
                <ng-container
                  matColumnDef="{{ navigatorService.Dictionary?.Name }}"
                >
                  <th mat-header-cell *matHeaderCellDef>
                    {{ navigatorService.Dictionary?.Name }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.Name }}</span>
                  </td>
                </ng-container>

                <ng-container
                  matColumnDef="{{
                    navigatorService.Dictionary?.OfficeCodePlaceHolder
                  }}"
                >
                  <th mat-header-cell *matHeaderCellDef>
                    {{ navigatorService.Dictionary?.OfficeCodePlaceHolder }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.OfficeCode }}</span>
                  </td>
                </ng-container>

                <ng-container
                  matColumnDef="{{ navigatorService.Dictionary?.Description }}"
                >
                  <th mat-header-cell *matHeaderCellDef>
                    {{ navigatorService.Dictionary?.Description }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element.Description }}</span>
                  </td>
                </ng-container>

                <ng-container
                  matColumnDef="{{ navigatorService.Dictionary?.Role }}"
                >
                  <th mat-header-cell *matHeaderCellDef>
                    {{ navigatorService.Dictionary?.Role }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span>
                      {{element.CurrentUserRole}}
                    </span>
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="[
                    navigatorService.Dictionary?.Name,
                    navigatorService.Dictionary?.OfficeCodePlaceHolder,
                    navigatorService.Dictionary?.Description,
                    navigatorService.Dictionary?.Role
                  ]"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="
                    let row;
                    columns: [
                      navigatorService.Dictionary?.Name,
                      navigatorService.Dictionary?.OfficeCodePlaceHolder,
                      navigatorService.Dictionary?.Description,
                      navigatorService.Dictionary?.Role
                    ]
                  "
                ></tr>
              </table>
            </div>
          </div>
          <div
            *ngIf="UOModel?.Dtos?.length <= 0 && !navigatorService.Loading"
            class="empty-table-box"
          >
            <img src="../../assets/icon/uo-empty-icon.svg" />
            <div class="description">
              {{ navigatorService.Dictionary?.EmptyUoViewMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
