import { Guid } from "guid-typescript";
export class Form {
    private Components: Array<FormComponent>;
    public Rows: Array<Row>
    public ColumnsGuids!: Array<string>;

    constructor() {
        this.Rows = new Array<Row>();
        this.Components = new Array<FormComponent>();
        this.AddRow();
        this.SetColumnsGuids();
        this.SetComponents();
    }
    //#region Builder
    public AddRow() {
        let index: number = 0;
        if (this.Rows.length > 0) {
            const indexes = this.Rows.map(r => r.Index);
            index = Math.max(...indexes) + 1;
        }
        this.Rows.push(new Row(index));
    }
    public DropRow(previousIndex: number, currentIndex: number) {
        const rowPrev = this.Rows.find(q => q.Index == previousIndex);
        if (rowPrev) {
            rowPrev.Index = currentIndex;
            this.RowsIndexes();
        }
    }
    public DeleteRow(index: number) {
        if (this.Rows.length > 1) {
            this.Rows.splice(index, 1);
            this.RowsIndexes();
        }
    }
    public LastRow(): Row {
        return this.Rows[this.Rows.length - 1];
    }
    public RowsIndexes() {
        for (let i = 0; i < this.Rows.length; i++) {
            this.Rows[i].Index = i;
        }
    }
    public SetColumnsGuids() {
        const guids = new Array<string>();
        for (const row of this.Rows) {
            const columnsGuids = row.Columns.map(q => q.Id);
            guids.push(...columnsGuids);
        }
        this.ColumnsGuids = guids;
    }
    public CloneComponent(component: FormComponent) {
        return JSON.parse(JSON.stringify(component));
     }
    //#endregion Builder

    //#region Toolbar
    private SetComponents() {
        //elements
        const imageComponent = new FormComponent('item-img.svg', 'Immagine', COMPONENTTYPE.IMAGE,0, SECTION.ELEMENT)
        const linkComponent = new FormComponent('item-link.svg', 'Link', COMPONENTTYPE.LINK, 1,SECTION.ELEMENT)
        const editorComponent = new FormComponent('item-editor.svg', 'Editor di testo', COMPONENTTYPE.TEXTEDITOR,2, SECTION.ELEMENT)
        const tableComponent = new FormComponent('item-table.svg', 'Tabella', COMPONENTTYPE.TABLE,3,SECTION.ELEMENT)
        this.Components.push(imageComponent);
        this.Components.push(linkComponent);
        this.Components.push(editorComponent);
        this.Components.push(tableComponent);

        //metadati
        const timeComponent = new FormComponent('item-time.svg', 'Tempo', COMPONENTTYPE.TIME,4,SECTION.METADATA)
        const dateComponent = new FormComponent('item-date.svg', 'Data', COMPONENTTYPE.DATE,5,SECTION.METADATA)
        const textComponent = new FormComponent('item-text.svg', 'Testo', COMPONENTTYPE.TEXT,6, SECTION.METADATA)
        const comboBoxComponent = new FormComponent('item-text.svg', 'Combobox', COMPONENTTYPE.COMBO,7, SECTION.METADATA)
        this.Components.push(timeComponent);
        this.Components.push(dateComponent);
        this.Components.push(textComponent);
        this.Components.push(comboBoxComponent);
    }
    public GetComponents(): Array<FormComponent> {
        return this.Components;
    }
    //#endregion Toolbar
}

export class Row {

    public Id: string;
    public Index: number;
    public CssClass: string = "row";
    public Columns: Array<Column>;
    constructor(index: number) {

        this.Index = index;
        this.Id = Guid.create().toString();
        this.Columns = Array<Column>();
        this.Columns.push(new Column(0, CSSCLASS.col_md_12));
    }
    SetColumns(columns: number) {
        this.Columns = [];
        for (let i = 0; i < columns; i++) {
            this.AddColumn();
        }
    }
    AddColumn() {
        let index: number = 0;
        if (this.Columns.length >= 0 && this.Columns.length < 3) {
            const cssClass = this.GetCssClass();
            const indexes = this.Columns.map(c => c.Index);
            index = Math.max(...indexes) + 1;
            this.ColumnsClasses(cssClass);
            this.Columns.push(new Column(index, cssClass));

        }
    }
    public DropColumn(previousIndex: number, currentIndex: number) {
        const rowPrev = this.Columns.find(q => q.Index == previousIndex);
        if (rowPrev) {
            rowPrev.Index = currentIndex;
            this.ColumnsIndexes();
        }
    }
    DeleteColumn(index: number) {
        if (this.Columns.length > 1) {
            this.Columns.splice(index, 1);
            this.ColumnsIndexes();
            const cssClass = this.GetCssClass(true);
            this.ColumnsClasses(cssClass);
        }
    }

    public ColumnsIndexes() {
        for (let i = 0; i < this.Columns.length; i++) {
            this.Columns[i].Index = i;
        }
    }

    ColumnsClasses(cssClass: string) {
        for (const column of this.Columns) {
            column.CssClass = cssClass;
        }
    }

    GetCssClass(remove: boolean = false) {
        if (!remove) {
            if (this.Columns.length + 1 == 1) {
                return CSSCLASS.col_md_12;
            } else if (this.Columns.length + 1 == 2) {
                return CSSCLASS.col_md_6;
            } else if (this.Columns.length + 1 == 3) {
                return CSSCLASS.col_md_4;
            }
        } else {
            if (this.Columns.length == 2) {
                return CSSCLASS.col_md_6;
            } else if (this.Columns.length == 1) {
                return CSSCLASS.col_md_12;
            }
        }
        return CSSCLASS.col_md_4;
    }
}

export class Column {
    public Index: number;
    public CssClass: string;
    public Id: string;
    Components: Array<any>;
    constructor(index: number, cssClass: string) {
        this.Index = index;
        this.Id = Guid.create().toString();
        this.CssClass = cssClass;
        this.Components = new Array<any>();

    }
    public DropComponent(currentIndex: number, component: FormComponent) {
        if (component) {
            component.Index = currentIndex;
            this.ComponentIndexes();
        }
    }
    public MoveComponent(previousIndex: number, currentIndex: number) {
        const componentPrev = this.Components.find(q => q.Index == previousIndex);
        if (componentPrev) {
            componentPrev.Index = currentIndex;
            this.ComponentIndexes();
        }
    }
    public RemoveComponent(component: FormComponent) {
        if (component) {
            this.Components.splice(component.Index,1);
            this.ComponentIndexes();
        }
    }
    public ComponentIndexes() {
        for (let i = 0; i < this.Components.length; i++) {
            this.Components[i].Index = i;
        }
    }
}

export class FormComponent {
    public Id: string;
    public Index: number;
    public Icon: string;
    public Description: string;
    public Section: string;
    public Type: COMPONENTTYPE = COMPONENTTYPE.NONE;
    constructor(icon: string, description: string, type: COMPONENTTYPE,index:number, section: string) {
        this.Index = index;
        this.Id = Guid.create().toString();
        this.Icon = icon;
        this.Description = description;
        this.Type = type;
        this.Section = section;
    }
}
enum CSSCLASS {
    col_md_4 = "col-md-4",
    col_md_6 = "col-md-6",
    col_md_12 = "col-md-12",
}

export enum COMPONENTTYPE {
    NONE = 'none',
    IMAGE = 'image',
    LINK = 'link',
    TEXTEDITOR = 'texteditor',
    TABLE = 'table',
    TIME = "time",
    DATE = "date",
    TEXT = "text",
    COMBO = "combo"
}
export enum SECTION {
    NONE = 'none',
    ELEMENT = 'element',
    METADATA = 'metadata'
}
