<div class="itdoc-page">
    <div class="row">
        <div class="col-md-11">
            <div class="title-container">
                <div class="valign-icon-text">
                    <img style="margin-right: 11px;" src="../../../../../assets/icon/dossier-black.svg">
                    <h2>{{navigatorService.Dictionary?.Dossier}}</h2>
                </div>
                <div>
                    <span class="current-step">{{Step}}</span><span class="step-separator">|</span><span
                        class="steps">{{Steps}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-1 text-end">
            <span class="material-icons icon-close" [mat-dialog-close]="null">
                close
            </span>
        </div>
    </div>

    <div *ngIf="Step===1">
        <p class="title-description">{{navigatorService.Dictionary?.DossierDescription}}</p>
        <div class="required-label">
            <span class="star">*</span>
            <span>{{navigatorService.Dictionary?.RequiredFields}}</span>
        </div>
        <mat-dialog-content class="default-scrollbar">
            <div class="form">
                <div class="row mb-3">
                    <div class="col-md-6 col-sm-12">
                        <app-input [DataValue]='Model?.Dto' [Property]="'Name'" [Type]="'text'" [Required]='true'
                            [Maxlength]="50" [Placeholder]="navigatorService.Dictionary?.DossierNamePlaceholder"
                            [Description]="navigatorService.Dictionary?.Maxlength50"></app-input>
                    </div>
                    <!-- Identificativo dossier -->
                    <div class="col-md-6 col-sm-12">
                        <app-input *ngIf="navigatorService.PageAction === 'New'" [DataValue]='Model?.Dto'
                            [Property]="'Key'" [Type]="'text'" [Required]='false' [Maxlength]="12"
                            [Placeholder]="navigatorService.Dictionary?.DossierIdPlaceholder" [Disabled]="true"
                            [Description]="navigatorService.Dictionary?.DescriptionDossierIdInput">
                        </app-input>
                        <app-input *ngIf="navigatorService.PageAction === 'Edit'" [DataValue]='Model?.Dto'
                            [Property]="'Key'" [Type]="'text'" [Required]='false' [Maxlength]="12"
                            [Placeholder]="navigatorService.Dictionary?.DossierIdPlaceholder" [Disabled]="true">
                        </app-input>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <app-select [Placeholder]="navigatorService.Dictionary?.SelectOrganizationUnitPlaceholder"
                            [Required]='true' [DataValue]='Model?.Dto?.UoId' [Property]="'Name'"
                            [Items]="OrganizationUnitModel.Dtos" [MultiSelect]="false" (OnChange)="SelectUo($event)">
                        </app-select>

                    </div>
                    <div class="col-md-6">
                        <app-input [DataValue]='Model?.Dto' [Property]="'Label'" [Type]="'text'" [Required]='true'
                            [Maxlength]="50" [Placeholder]="navigatorService.Dictionary?.DossierLabelPlaceholder"
                            [Description]=""></app-input>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                <app-mt-autocomplete #autocompleteResponsible [appearance]="'fill'"
                                    [Items]="AccountModel?.Dtos" [DisplayProperties]="['DisplayName']"
                                    [Placeholder]="navigatorService.Dictionary?.PersonCharge"
                                    [SearchProperties]="['DisplayName']" [LoaderType]="'Spinner'" [DropDownMode]="true"
                                    (SelectionChange)="ResponsibleSelected($event)" [Multiple]="false"
                                    [Required]="true">
                                </app-mt-autocomplete>
                            </div>
                        </div>
                        <div class="operators-container tiny-scrollbar" *ngIf="ResponsibleDto">
                            <div class="row">
                                <div class="col-10">
                                    <div class="d-flex">
                                        <img *ngIf="ResponsibleDto?.Avatar" class="avatar-circle"
                                            [src]="'data:image/png;base64,' + ResponsibleDto?.Avatar"
                                            style="margin-right: 10px;">
                                        <span class="display-name">{{ResponsibleDto?.DisplayName}}</span>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <mat-icon (click)="DeleteAccountDossier(ResponsibleDto)" class="icon-text-right">
                                        close
                                    </mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                <app-mt-autocomplete #autocompleteUser [appearance]="'fill'"
                                    [Items]="GetFilterAccounts(false)" [DisplayProperties]="['DisplayName']"
                                    [Placeholder]="navigatorService.Dictionary?.SelectOperators"
                                    [SearchProperties]="['DisplayName']" [LoaderType]="'Spinner'" [DropDownMode]="true"
                                    (SelectionChange)="UsersSelected($event)" [Multiple]="false" [OptionIcon]="'add'"
                                    [Required]="true">
                                </app-mt-autocomplete>
                            </div>
                        </div>
                        <div class="operators-container tiny-scrollbar">
                            <ng-container *ngFor="let dto of AccountDossierModel.Dtos">
                                <div *ngIf="dto.Role !=='Responsible'" class="row">
                                    <div class="col-10">
                                        <div class="d-flex">
                                            <img *ngIf="dto.Avatar" class="avatar-circle"
                                                [src]="'data:image/png;base64,' + dto.Avatar"
                                                style="margin-right: 10px;">
                                            <span class="display-name">{{dto.DisplayName}}</span>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <mat-icon (click)="DeleteAccountDossier(dto)" class="icon-text-right">close
                                        </mat-icon>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </div>

    <div *ngIf="Step===2">
        <p class="title-description">{{navigatorService.Dictionary?.DossierDescription3}}</p>
        <mat-dialog-content style="min-height: 26vh;overflow: hidden ">
            <div>
                <div class="row my-2">
                    <div class="col-md-12 col-sm-12 mb-2 ">
                        <app-search #search [Placeholder]="navigatorService.Dictionary?.DossierSearchPlaceholder"
                            [HaveFilters]="false" [HaveActions]="false" [ModalStyle]="true"
                            (OnEnterPress)="Search($event)" (OnClearBtnPress)="Search($event)">
                        </app-search>
                    </div>
                    <div class="col-md-6 col-sm-12">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="row breadcrumb-lite" *ngIf="this.CurrentFolder">
                            <div class="col-md-4">
                                <button mat-icon-button (click)="BreadcrumbBack()" [disabled]="!this.CurrentFolder">
                                    <mat-icon class="icon-text-right"> keyboard_arrow_left</mat-icon>
                                    <span>{{navigatorService.Dictionary?.Back}}
                                    </span>
                                </button>
                            </div>
                            <div class="col-md-4 text-center current-folder">
                                <span>{{CurrentFolder?.Name}}</span>
                            </div>
                            <div class="col-md-4"></div>
                        </div>

                        <div class="it-doc-table-container default-scrollbar" style="height: 26vh; overflow: auto">
                            <table mat-table [dataSource]="DataSource" matSort>
                                <ng-container matColumnDef="checkbox">
                                    <th mat-header-cell *matHeaderCellDef class="checkbox-column"></th>
                                    <td mat-cell *matCellDef="let element">
                                        <mat-checkbox *ngIf="element.Type === 'file'" [checked]="element.Checked"
                                            (change)="SelectObjectDossier($event, element)"></mat-checkbox>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="FileIcon">
                                    <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
                                    <td mat-cell *matCellDef="let element" (click)="ObjectsClick(element)">
                                        <img src=" {{element.Icon}}" class="{{element.ClassIcon}}">
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnName}}">
                                    <th mat-header-cell *matHeaderCellDef>
                                        {{navigatorService.Dictionary?.TableColumnName}} </th>
                                    <td mat-cell *matCellDef="let element" (click)="ObjectsClick(element)">
                                        <span>
                                            {{element.Name + (element.Type==='file'?'.' +
                                            commonService.GetExtension(element.PhysicalName):'')}}
                                        </span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnCreate}}">
                                    <th mat-header-cell *matHeaderCellDef>
                                        {{navigatorService.Dictionary?.TableColumnCreate}} </th>
                                    <td mat-cell *matCellDef="let element" (click)="ObjectsClick(element)">
                                        <span>
                                            {{element.DisplayName}}
                                        </span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnUpdate}}">
                                    <th mat-header-cell *matHeaderCellDef>
                                        {{navigatorService.Dictionary?.TableColumnUpdate}} </th>
                                    <td mat-cell *matCellDef="let element" (click)="ObjectsClick(element)">
                                        <span *ngIf="element.Deep>=0">
                                            {{element.ModificationDateString}}
                                        </span>
                                        <span *ngIf="!element.Deep">
                                            {{element.UpdateDate | date:'dd/MM/yyyy'}}
                                        </span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="ArrowIcon">
                                    <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
                                    <td mat-cell *matCellDef="let element" (click)="ObjectsClick(element)">
                                        <mat-icon *ngIf="element.Type==='folder'">arrow_forward_ios</mat-icon>
                                    </td>
                                </ng-container>

                                <tr mat-header-row
                                    *matHeaderRowDef="['checkbox','FileIcon', navigatorService.Dictionary?.TableColumnName,navigatorService.Dictionary?.TableColumnCreate,navigatorService.Dictionary?.TableColumnUpdate,'ArrowIcon']">
                                </tr>
                                <tr mat-row class="clickable-icon"
                                    *matRowDef="let row; columns: ['checkbox','FileIcon', navigatorService.Dictionary?.TableColumnName,navigatorService.Dictionary?.TableColumnCreate,navigatorService.Dictionary?.TableColumnUpdate,'ArrowIcon'];">
                                </tr>

                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-sm-12 ">
                      <app-pager #pager (onPaging)="PagingChange($event)"></app-pager>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </div>

    <div *ngIf="Step===3">
        <p class="title-description">{{navigatorService.Dictionary?.DossierDescription4}}</p>
        <mat-dialog-content class="default-scrollbar">
            <div class="form mb-5">
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <p class="title-description-2">{{navigatorService.Dictionary?.DossierNamePlaceholder}}
                        </p>
                        <div class="summary-box">
                            <span class="value-text">{{Model?.Dto?.Name}}</span>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <p class="title-description-2">{{navigatorService.Dictionary?.DossierIdPlaceholder}}
                        </p>
                        <div class="summary-box">
                            <span class="value-text">{{Model?.Dto?.Key || '-'}}</span>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <p class="title-description-2">{{navigatorService.Dictionary?.SelectOrganizationUnitPlaceholder}}
                        </p>
                        <div class="summary-box">
                            <span class="value-text">{{Model?.Dto?.Uo?.Name}}</span>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-4 col-sm-12">
                        <p class="title-description-2">{{navigatorService.Dictionary?.TableColumnDossierOwner}}
                        </p>
                        <div class="summary-box default-scrollbar">
                            <span style="display: inline;" class="value-text">{{Model.Dto?.CreatorDisplayName}}</span>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <p class="title-description-2">{{navigatorService.Dictionary?.PersonCharge}}
                        </p>
                        <div class="summary-box">
                            <span class="value-text">{{ResponsibleDto.DisplayName}}</span>
                        </div>
                    </div>

                    <div class="col-md-4 col-sm-12">
                        <p class="title-description-2">{{navigatorService.Dictionary?.DossierLabelPlaceholder}}
                        </p>
                        <div class="summary-box">
                            <span class="value-text">{{Model?.Dto?.Label}}</span>
                        </div>
                    </div>

                </div>
                <div class="row mt-3">
                    <div class="col-md-12 col-sm-12">
                        <p class="title-description-2">{{navigatorService.Dictionary?.OperatorDossierPlaceholder}}
                        </p>
                        <div class="summary-box tiny-scrollbar">
                            <div *ngFor="let account of AccountDossierModel.Dtos">
                                <div *ngIf="account.Role === 'Operator'">
                                    <span style="display: inline;" class="value-text">{{account.DisplayName}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-12 col-sm-12">
                        <p class="title-description-2">{{navigatorService.Dictionary?.Documents}}
                        </p>
                        <div class="summary-box tiny-scrollbar">
                            <div *ngFor="let object of ObjectDossierModel.Dtos">
                                <span class="icon-text-right"><img src="{{object.Icon}}"></span>
                                <span style="display: inline;" class="value-text">{{object.Name + '.' +
                                    commonService.GetExtension(object.PhysicalName)}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </mat-dialog-content>
    </div>

    <div mat-dialog-actions align="end" class="dialog-buttons-container">
        <button *ngIf="Step===1" class="it-doc-btn-cancel" style="margin-inline-end: auto;" [mat-dialog-close]="null">
            <span class="button-text">{{navigatorService.Dictionary?.Cancel}}</span>
        </button>
        <button *ngIf="Step>1" class="it-doc-btn-cancel" style="margin-inline-end: auto;" (click)="Back()">
            <span class="button-text">{{navigatorService.Dictionary?.Cancel}}</span>
        </button>
        <button *ngIf="Step<3" (click)="Next()" mat-button class="it-doc-btn-primary"
            [disabled]="!ValidateForm(this.Step,false)">
            <span class="button-text">{{navigatorService.Dictionary?.Next}}</span>
        </button>
        <button *ngIf="Step===3" (click)="Save()" mat-button class="it-doc-btn-primary">
            <span *ngIf="!data" class="button-text">{{navigatorService.Dictionary?.Create}}</span>
            <span *ngIf="data" class="button-text">{{navigatorService.Dictionary?.Update}}</span>
        </button>
    </div>
</div>
