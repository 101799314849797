import { Component } from '@angular/core';
import { InputComponent } from '../../../../../../custom-components/input/input.component';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent<TDto> extends InputComponent<TDto> {

  
}
