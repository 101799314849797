import { DictionaryInterface } from './dictionary.interface';
export class Dictionary implements DictionaryInterface {
  DocumentNumber = 'Number of documents';
  LimitFiles = 'You can upload a maximum of 5000 files per single PDA';
  PDDNotAvailable = 'PDD not available';
  Index = 'payout index';
  ShowLog = 'Error details';
  CreatedPda = 'Storage package created successfully';
  DeletedPda: 'Deleted storage package';
  GeneratePda = 'Generate Storage Package';
  ArchiveList = 'Archive List';
  ArchiveContent = 'Archive Content:';
  PdaContent = 'Pda Content:';
  NewPdaTitle = 'Generate deposit package index';
  NewPdaDescription =
    'The form allows you to create the payment package by inserting one or more documents. For each document you will need to specify its metadata to create the index. ';
  AddDocuments = 'Add documents';
  DocClassDescription =
    'Select the document class and continue to make the payment';
  DocClass = 'Document class';
  NewPda = 'New storage package (PDA)';
  EditPda = 'Edit Storage(PDA)';
  CreatePDA = 'Create storage package';
  UploadPDA = 'Ipload storage package';
  Conservation = 'Conservation';
  ConservationLoginDescription =
    'Enter the credentials of the DocFly conservation service';
  ConservationLoginTitle = 'Sign in Docfly';
  Username = 'Username';
  DocFlyLogin = 'Login';
  BusinessName = 'Business name';
  VatNumber = 'Vat number';
  FiscalCode = 'Fiscal code';
  EmptyArchiveMessage = 'No archive to view';
  PaymentDate = 'Payment Date';
  RetentionDate = 'Retention date';
  EmptyPdasMessage = 'No deposit packages to display';
  ViewPda = 'Storage Packages (PDA)';
  DetailPda = 'Storage Package Details';
  DownloadPDD = 'Download PDD';

  WizardEndTitle =
    'How {#APPNAME#} works ends here, but you always <br> could navigate it clicking there';
  WizardEndDescription =
    'We wish you a good job with your projects and colleagues.';
  LetsStart = "Let's start";
  Use = 'Use ';
  WizardDescription1 =
    'to easily manage your <strong>smartworking</strong> ,creating <strong>documents</strong> and <strong>dossiers</strong>, inviting collegues to ' +
    'collaborate to finalize your <strong>protocols</strong>. <strong>Boost</strong> your <strong>productivity</strong> with advanced ' +
    'comunications services and monitoring tools.';
  WizardDescription2 =
    'You are an<strong>Admin</strong> of {#APPNAME#}, ' +
    'let’s discover all the functionalities of your profile.';
  WizardUserDescription2 =
    'You are an<strong>Worker</strong> of {#APPNAME#}, ' +
    'let’s discover all the functionalities of your profile.';
  WizardWelcome = 'Welcome on board {#DISPALYNAME#}';
  JumpSection = 'Jump this section';
  WizardSkipAll = 'Skip all';
  HowWorks = 'How {#APPNAME#} works';
  ErrorRequired = 'Field required';
  Timestamp = 'Timestamp';
  TimestampDescription = 'Apply timestamp';
  TimestampUsername = 'Time stamp username';
  TimestampPassword = 'Time stamp password';
  DossierNotFound = 'The dossier is no longer available';
  DocumentNotFound = 'Document not available';
  TipologyNotFound = 'Metadata structure not available';
  UoNotFound = 'The organizational unit is no longer available';
  SignatureOwner = 'Signature’s owner';
  English = 'English';
  Italian = 'Italian';
  Language = 'Language';
  ValidityCertificate = 'Validity of the certificate';
  CertifyingAuthority = 'Certifying Authority';
  DomainQualifier = 'Domain Qualifier';
  OrganizationName = 'Organization type';
  AlternativeName = 'Alternative Name';
  SerialNumber = 'Serial Number';
  SignVerify = 'Signature details';
  NotificationHistory = 'Notification history';
  Yesterday = 'Yesterday at';
  Today = 'Today at';
  InvalidOTP = 'Invalid OTP';
  AddSignature = 'Add signature';
  GraphicSign = 'Graphics';
  InvisibleSign = 'Invisible';
  SignatureMode = 'Signature Mode';
  SignatureType = 'Type of signature';
  SignaturePlace = 'Location';
  SignatureReason = 'Reason';
  Operator = 'User';
  ComparePassword = 'Passwords must match';
  CompareEmail = 'Email address must match';
  ComparePhone = 'Phone number must match';
  ConfirmMail = 'Confirm your new email ';
  ConfirmPhone = 'Confirm your new telephone ';
  EditInfoDescription1 = 'Edit user settins and confirm';
  EditInfoDescription2 = 'Edit your password and confirm';
  Edit = 'Edit';
  Phone = 'Phone';
  TitleInfo1 = 'Name and birth date';
  TitleInfo2 = 'Password and contacts';
  TitleInfo3 = 'Operational information';
  DocsRole = '{#APPNAME#} Role';
  Birthdate = 'Birth date';
  PrincipaInfo = 'Principal info';
  Profile = 'Profile';
  FolderNameEmpty = 'Please enter the folder name';
  AddFolder = 'Add a Folder';
  Signatory = 'Signatory';
  SelectOperators = 'Operators';
  PersonCharge = 'Person in charge';
  CollateSuccess = 'Collation completed successfully';
  TrashedFiles = 'Files deleted';
  TrashedFileNotCmplete = 'One or more files could not be deleted';
  CountAttachment = 'Number of attachments';
  Title = 'Title';
  Recipient = 'Recipient';
  Sender = 'Sender';
  FileAlreadyExists = 'File alredy exists';
  UploadError = 'Upload error';
  Upload = 'Upload';
  NewUpload = 'New upload';
  Uploading = 'Uploading {#filename#}';
  UploadCompleted = 'Upload completed';
  ExportLog = 'Export to txt';
  Close = 'Close';
  DragAttachments ='Drag your attachments here, or click to select them from your computer ';
  SelectFile = 'Click to select the file to upload';
  ManageAttachements = 'Manage your attachments';
  AttachedDocuments = 'Attached documents';
  Attached = 'Attached';
  LastActivity = 'Last activity';
  ActivityEmpty = 'There are no activities';

  MessageConvertFail = 'Document conversion failed';
  TypeOtp = 'Type otp';
  ToSignSuccess = 'Signed successfully';
  ResendOtp = "Didn't you get the code? Click to resend";
  TypeOtpCode = 'Type the otp code';
  SignatureDescription2 = 'Select and add a signature';
  SignatureService = 'Signature’s service';
  SignatureDescription = 'Set your signature’s data';
  EmptySignatureMessage = 'There are no signing accounts';
  ProfileName = 'Profile name';
  Service = 'Service';
  Sign = 'Sign';
  YourRequest = 'My request';
  DeleteField = 'Delete field';
  MarkAllAsRead = 'Mark all as read';
  MoveToTrashSuccessMessage = 'Moved to Trash Successfully';
  MoveToTrash = 'Move to trash';
  NoDeletedUoMessage = 'There are no Organizational Units deleted';
  YourUoDeleted = 'Your Organizational Units Deleted';
  AllUoDeleted = 'All Organizational Units Deleted';
  TrashedUo = 'UO Deleted';
  AssociatedFiles = 'Associated files';
  AssociatedUsers = 'Associated users';
  Identifier = 'Identifier';
  TableColumnResponsible = 'Responsible';
  TableColumnDossierIdentifier = 'Identifier';
  DossierUpdateMessage = 'Dossier successfully modified.';
  DossierArchivedMessage = 'Dossier archived successfully.';
  DossierDeleteMessage = 'Dossier successfully deleted.';
  DeleteUserMessage = 'User deleted';
  ResetPasswordSend =
    'Password recovery email sent successfully. Check your inbox';
  AccountVerifiedSuccessfully = 'Account verified';
  Password = 'Password';
  DescriptionInvitationldapForm =
    'Enter your password to verify your {#APPNAME#} account';
  SearchLdap = 'Search in your ldap';
  ImportFromLdap = 'Import from ldap';
  MatchPasswordError = 'The passwords entered are not the same';
  MissingMail = 'Insert Email';
  UserNotFound = 'Email not found. Please enter your account email.';
  ResetFilters = 'Reset Filters';
  MetadataStructurePlaceholder = 'Metadata Structure';
  CreationDatePlaceholder = 'Creation Date';
  FileExtensionPlaceholder = 'File Extension';
  StatusPlaceholder = 'Status';
  UoPlaceholder = 'Organizational Unit';
  EmptyWorkflowMessage = 'There are no Workflow yet!';
  DossierClose = 'Archived Dossier';
  Open = 'Open';
  RemoveFromDossier = 'Remove from dossier';
  StateRevisioned = 'Revisioned';
  WorkflowReport = 'Workflow Report';
  WorkflowFiles = 'Document workflows';
  TableColumnStep = 'Step';
  InvalidFormat = 'Invalid file format';
  StateProtocolled = 'Protocolled';
  SendToProtocol = 'to Register';
  ProtocolTitle = 'Protocol';
  Protocol = 'Protocol';
  YourDeleteDocument = 'Your Deleted Documents';
  AllDeleteDocument = 'All Deleted Documents';
  StateReject = 'Rejected';
  RejectedSuccess = 'Rejected';
  Reject = 'Reject';
  ToReject = 'to Reject document';
  RejectTitle = 'Reject';
  StateEdit = 'In edit';
  RequestEditingSuccess = 'Request of edit sent';
  ToRequestEditing = 'Request to edit';
  RequestEditingTitle = 'Request Editing';
  FilesArchived = 'Archived Documents';
  ToArchive = 'to Archive';
  StateArchived = 'Archived';
  StateApproved = 'Approved';
  ToApprove = 'to Approve';
  StateInApproval = 'In Approval';
  NotAuthorizedMessage = 'Not authorized';
  SendToApprovationSuccess = 'sent for approvation';
  SendToRevisionSuccess = 'sent for review';
  StateInRevision = 'In Revision';
  SendToApprovation = 'Send to Approvation';
  SendToRevision = 'Send to Revision';
  Approve = 'Approve';
  SendToRevisionTitle = 'Send to Revision';
  StateInProgress = 'In Progress';
  Workflow = 'Workflow';
  TipologyExist = 'Metadata structure already exist';
  UoExist =
    'Organizational unit already exist. Verify that the name and office code are correct';
  Admin = 'Admin';
  ToCreateSuccessF = 'correctly Edit ';
  ToEditSuccessF = 'correctly Created';
  ToEditSuccess = 'correctly Edit ';
  ToCreateSuccess = 'correctly Created';
  ToRestore = 'To Restore';
  RestoreSuccess = 'Restore successfull';
  AllFileSelected = 'all file selected';
  TrashedFile = 'has been trashed';
  ToDelete = 'to Delete';
  AreYouSureWantMsg = 'Are you sure you want';
  SendToApprovationTitle = 'Send to Approvation';
  NewFolderDescription = 'Give your folder a name and upload your files inside.';
  TableColumnVersion = 'Version';
  Favourites = 'Favourites';
  AllDocument = 'All Document';
  YourDocument = 'Your Document';
  DossierLabelPlaceholder = 'Label';
  YourDossier = 'Your Dossier';
  AllDossier = ' All Dossier';
  TableColumnStatus = 'Status';
  TableColumnDossierOwner = 'Dossier Owner';
  TableColumnTeam = 'Team';
  TableColumnShared = 'Shared with';
  AddUser = 'Add User';
  Export = 'Export';
  ExpirationDate = 'Expiration date';
  Maxlength16: 'Max. 16 characters';
  DossierFilterPlaceholder = 'Select dossiers';
  DoossierKeyFilterPlaceholder = 'Select dossiers key';
  Filter = 'Filters';
  DossierFilterSubTitle =
    'Select the parameters of the dossiers you want to view';
  CleanFilter = 'Clear filters';
  RunWorkflowDescription =
    'Select one or more files to be included in the workflow';
  IntoWork = 'Back to work';
  ActivityTitleShare = 'Sharing';
  ActivityTitleDossier = 'Dossier';
  ActivityTitleDownload = 'Download';
  ActivityTitleCopy = 'Copy';
  ActivityTitleUpdate = 'Edit';
  ActivityTitleCreate = 'Creation';
  ActivityListFile = 'FILE ACTIVITIES';
  ActivityRemoveShare = 'removed the sharing of the';
  ActivityUpdateShare = 'changed the sharing of the';
  PrepositionWith = 'with';
  ActivityCreateShare = 'shared';
  ActivityRemoveDossier = 'removed from the dossier';
  ActivityAddDossier = 'added to the dossier';
  ActivityDownload = 'download';
  ActivityCopy = 'copied';
  ActivityUpdate = 'modified';
  ActivityCreate = 'created';
  ActivityFile = 'the file';
  ActivityFolder = 'the folder';
  ActivitySuject = '';
  EmptySignage = 'No signature affixed';
  ShareOthers =
    'Shared with <b> {{|DISPLAYNAMEUID|}} </b> by <b> {{|DISPLAYNAMEOWNERID|}} </b>';
  NewDocumentPdf = 'New PDF';
  EditDocumentDescription2 = 'Edit document attributes';
  SignatureTabTitle = 'Signatures affixed';
  OTPValidationMessage = 'Enter the authentication OTP';
  OTPPlaceholder = 'OTP';
  OTPDescription = 'Enter the signature OTP';
  OTPTitle = 'Authentication';
  RunningWorkflow = 'The workflow has been started';
  RemovePassword = 'Remove password';
  ShareBy = 'Shared by';
  ShareWith = 'Shared with';
  DeleteShare = 'Remove';
  SetExipryDate = 'Set expiration';
  SetPassword = 'Set password';
  DenyDownload = 'Deny download';
  AllowEdit = 'Allow editing';
  ShareWithPlaceholder = 'Share with';
  RunWorkflow = 'Start workflow';
  TableColumnState = 'State';
  FileExist = 'A file with this name already exists, please choose another';
  AdvancedSearchDescription =
    'Perform an advanced search by applying one or more filters';
  CreationDate = 'Creation date';
  ModificationDate = 'Modification date';
  SearchInMetadata = 'Search in metadata';
  SearchInFiles = 'Search in files';
  AdvancedSearch = 'Advanced Search';
  Apply = 'Apply';
  SaveErrorAddDossier = 'Could not add one or more files to the dossier';
  TableColumnProtocol = 'Protocol';
  ScanDocument = 'Pdf';
  OperationSuccess = 'The operation was successful.';
  MimeTypesAllowed =
    'The selected document type does not accept the format of the file you want to create';
  MessagevalidationFormDigitalsignage = 'Fill in all fields of the form';
  MessageSignatureSuccess = 'The document has been signed';
  MessageSignatureFileNotFound = 'The document was not found.';
  RestoreDocument = 'Restore Document';
  RestoreDocumentDescription =
    'Do you want to proceed with restoring documents?';
  TrashedSuccess = 'The files have been moved to the trash';
  RestoredSuccess = 'Restored files';
  PermanentlyDelete = 'Permanently delete';
  Restore = 'Restore';
  FilesDelete = 'Deleted files';
  DeleteSuccess = 'Deletion successful';
  DeleteDocument = 'Delete document';
  DeleteDocumentDescription = 'Are you sure you want to delete?';

  SelectMoveCopyObject = 'Select destination';
  Copy = 'Copy';
  Move = 'Move';
  EditFolder = 'Edit Folder';
  EditDocument = 'Edit document';
  MoveCopy = 'Move or Copy';
  FoldersSelected = 'Selected';
  Selected = 'Selected';
  File = 'File';
  FolderSelected = 'Selected';
  Folders = 'Folders';
  Selecteds = 'Selected';
  AddFavourites = 'Add to Favorites';
  Modify = 'Modify';
  Signature = 'Digital signature';
  SendToEmail = 'Send via email';
  Send = 'Send';
  Crypt = 'Digit';
  Download = 'Download';
  Delete = 'Delete';
  CreateFromTemplate = 'Create file from Template';
  Template = 'Template';
  NewDocumentDescription4 = 'Upload your files from your desktop.';
  ValidatorSelectedFolder = 'Select a folder';
  ValidatorMessage = 'Fill in all required fields';
  ValidatorMessageResponsible = 'Please select at least one Responsible';
  FolderExist = 'Folder already exists. Please choose another name ';
  NewFolderDescription2 =
    'Select the users who will have access to this folder';
  FolderMessageValidator1 = 'Please enter folder name and destination';
  FolderName = 'Folder Name';
  NewFolder = 'New Folder';
  TipologySummarySubTitle1 = 'Document type name';
  TipologySummaryDescription =
    'Do you confirm the new Document Type with the following characteristics? ';
  MetadataToken = 'Define the metadata token';
  OperatorWorkPlaceholder = 'Who can work it';
  TokenPlaceholder = 'Insert a token';
  OperatorWorks = 'Who can work with this data';
  SelectedItems = 'Selected items inserted';
  AddItem = 'Add option';
  MaxPlaceHolder = 'Max.';
  Characters = 'characters';
  Maxlength500 = 'Max. 500 characters';
  Update = 'Edit';
  OrganizationalUnitTitleEdit = 'Change Organizational Unit';
  OrganizationalUnitDescription5 = 'Dependence';
  OrganizationalUnitDescription6 = 'Number of users';
  OrganizationalUnitTitle = 'New organizational unit';
  OrganizationalUnitDescription4 =
    'Select the hierarchically superior organizational unit';
  OrganizationalUnitDescription3 =
    'Are you sure you want to create a new Organizational Unit with these characteristics?';
  OrganizationUnitNamePlaceholder = 'Organization Unit Name';
  OfficeCodePlaceHolder = 'Office code';
  DescriptionUoPlaceholder = 'Organizational unit description';
  GroupPlaceholder = 'Select a group';
  SelectUserPlaceholder = 'Select User';
  SelectOrganizationUnitPlaceholder = 'Organizational Unit';
  OrganizationalUnitDescription2 =
    'Import users from a group or alternatively select them manually';
  OrganizationalUnitDescription = 'Set the organizational unit data';
  UoImageTitle = 'There are not Organizational units';
  UoImageIstruction1 = 'Create a new organizational unit';
  UoImageIstruction2 = 'Define the users who are part of it';
  UoImageIstruction3 = 'Set relationship with another organizational unit';
  UoSubTitleDescription =
    'Set the different organizational units that operate on the document based on their hierarchical relationship';
  UoSubTitle = 'Define organizational units';
  Maxlength250 = 'Max. 250 characters';
  FieldRequired = 'Make Field Required';
  TipologyPlaceholderField = 'Insert the field header';
  TipologyFieldPreview = 'Preview element';
  TipologyDescription3 = 'Define the parameters of the field';
  EmptyBasket = "You haven't entered generic fields yet";
  TipologyDescription2 =
    'Select the fields useful to build your type of document';
  FieldPlaceholder = 'Select generic fields';
  TemplateUploadPlaceholder = 'Upload template';
  ButtonUpload = 'Upload';
  NewDocumentDescription3 = 'Select the destination folder';
  TableColumnName = 'Name';
  TableColumnCreate = 'Created';
  TableColumnUpdate = 'Last Edit';
  TableColumnSize = 'Size';
  All = 'All';
  Back = 'Back';
  OrganizationalUnits = 'Organizational Units';
  OrganizationalUnit = 'Organizational Unit';
  AccountPlaceholder = 'Who can work it';
  DocumentName = 'Document Name';
  Maxlength50 = 'Max. 50 characters';
  NewDocumentDescription2 = 'Set the properties of the new document';
  NewDocumentDescription =
    'Here you can add, work and modify all the primary information of the document';
  NewDocument = 'New file';
  UploadDocument = 'Upload File';
  TxtDocument = 'Note';
  PptDocument = 'Presentation';
  XlsDocument = 'Spreadsheet';
  DocDocument = 'Text document';
  AppsAndProcessess = 'Apps and processes';
  CreateAppsRequestDescription = 'Create a new business request app';
  AppsRequest = 'Request App';
  MenuDialogDescription = 'Select the type of upload';
  CreateDossierDescription =
    'Create a dossier, select the documents required and define the operators involved';
  CreateFolderDescription = 'Create a folder for managing your documents';
  CreateTipologyDescription = 'Create document type';
  CreateDocumentDescription = 'Upload a new document from your computer';
  DocumentTipology = 'Document Type';
  Dossier = 'Dossier';
  Document = 'Document';
  Folder = 'Folder';
  Description = 'Description';
  Years = 'Years';
  Months = 'Months';
  Days = 'Days';
  CreateTipology = 'Create document type';
  Tipology = 'Metadata Structure';
  Next = 'Next';
  Cancel = 'Cancel';
  SelectUser = 'Select User';
  UOUsersDescription =
    'Define the users associated with the organizational unit';
  Users = 'Users';
  SaveError = 'An error occurred while saving.';
  Name = 'Name';
  Create = 'Create';
  CreateUO = 'Create Organizational Unit';
  CreateDocument = 'Create Document';
  CreateDossier = 'Create Dossier';
  SaveSuccess = 'Save successful.';
  Documentary = 'Documentary';
  Dossiers = 'Dossiers';
  DossiersDescription = 'Monitor your files';
  Documents = 'Documents';
  Activities = 'Activities';
  OrganizationUnitPlaceholder = 'Select organizational unit';
  TipologyTitle = 'Document type';
  TipologyDescription = 'Create document type and metadata';
  ModifyTipologyDescription = 'Modify document type and metadata';
  DocumentTipologyFileType = 'Accepted files';
  // Placeholder
  TipologyPlaceholder = 'Metadata structure';
  TipologyNamePlaceholder = 'Document type name';

  RolePlaceholder = 'Select a role';

  DossierDescription = 'Set the properties of the new dossier';
  DossierDescription2 = 'Select approvers and who can process the dossier';
  DossierDescription3 =
    'Select the files you want to include in the dossier from Your documents';
  DossierDescription4 =
    'Are you sure you want to create a new Dossier with these characteristics?';
  DossierNamePlaceholder = 'Dossier Name';
  DossierIdPlaceholder = 'Dossier identifier';
  ResponsiblePlaceholder = 'Responsible';
  OperatorDossierPlaceholder = 'Operators';
  MaxLength12 = 'Max. 12 characters';
  DossierSearchPlaceholder = 'Search (Protocols, files and documents)';
  RequiredFieldsMessage = 'Please complete all required fields';
  RequiredFields = 'Required Fields';
  DossierNameSummary = 'Dossier Name';
  DossierIdSummary = 'Dossier key';
  LastEdit = 'Last modified';
  DossierFile = 'Associated files';
  DossierFileDescription =
    'View, process documents or disassociate them from the file';
  UnlinkFile = 'Unlink';
  NoFileDossier = 'No associated files';
  SearchFiltersLabel = 'Filter';
  SearchActionsLabel = 'Actions';
  DossiersViewSearchPlaceholder = 'Search Dossiers';
  PageSubtitleSearch = 'Search your document';
  DossiersPageSubtitleDescription =
    'Search the files of your competence in the company document';
  TipologySearchPlaceholder = 'Search document type';
  TipologyPageSubtitleDescription =
    'Search the types of documents of your competence in the company document';
  OrganizationUnitSearchPlaceholder = 'Search in your documents section';
  DocumentPageSubtitleDescription =
    'Search protocols, files and documents of your competence in the company document';
  DocumentViewSearchPlaceholder = 'Search (Protocol, document name)';
  AddToDossier = 'Collate';
  SelectAddToDossier = 'Select one or more dossiers';
  Add = 'Add';
  InLate = 'In late';
  InTime = 'In time';
  FolderSearchPlaceholder = 'Fint folder';
  Confirm = 'Confirm';
  AppsAndProcessesSectionTitle = 'Apps and processes';
  DocumentarySectionTitle = 'Documental';
  MyRequestsTitle = 'Status of my requests';
  MyDeliberateRequestsTitle = 'Your deliberate requests';
  OthersRequestsTitle = 'Status of Others Requests';
  Import = 'Import';
  CreateUoDescription =
    'Create a new organizational unit, defining its structure and users';
  DossierImageTitle = 'There are no dossiers';
  DossierImageDescription1 =
    'Define the name and attributes of your new dossier';
  DossierImageDescription2 =
    'Select the files you want to associate and save the dossier';
  TipologyImageTitle = 'There are no document types';
  TipologyImageDescription1 =
    'Define the name and attributes of your new document type';
  TipologyImageDescription2 =
    'Set the metadata structure of the document type and save';
  UnderProcessing = 'In process';
  InConservation = 'In conservation';
  Approved = 'Approved';
  InModification = 'In modification';
  InApproval = 'In Approval';
  ExportUoTree = 'Export organizational unit tree';
  ArchiveTipologyTitle = 'Archive types';
  Archived = 'Archived';
  Archive = 'Archive';
  ArchiveTipologyMsg =
    'Document types <b> {|tipologyNames|} </b> are associated with documents. By confirming the document types will be archived and not deleted. ';
  ArchiveTipologyMsg2 =
    'Do you want to proceed with archiving the selected types?';
  DeletedTipologySuccessMsg = 'Types successfully deleted.';
  DeletedTipologyErrorMsg =
    'Some of the selected types were not deleted due to an error.';
  DeleteTipologyTitle = 'Delete types';
  DeleteTipologyMsg =
    'Do you want to proceed with the deletion of the selected types?';
  DescriptionExpandCard = 'View and process documents {|documentState|}';
  BusinessDocuments = 'Business Documents';
  SearchUoPlaceholder = 'Search organizational unit';
  DeleteDossierTitle = 'Delete Dossiers';
  DeleteDossierDescription =
    'Do you want to proceed with the deletion of the selected dossiers?';
  SharePasswordDialogTitle = 'Share password';
  SharePasswordDialogBubtitle =
    'Sharing requires a password to access the file';
  SharePasswordPlaceholder = 'Share password';
  ErrorSharePassword = 'Invalid password';
  InResolution = 'In resolution';
  Determined = 'Determined';
  ApprovedRequests = 'Approved';
  Refuse = 'Refuse';
  Member = 'Member';
  Date = 'Date';
  Role = 'Role';
  Sharing = 'Sharing';
  DescriptionDossierIdInput =
    'It will be automatically assigned by the system in the form FS20210000001';
  SearchPlaceholder = 'Search';
  TrashedDocumentSubtitleSearch = 'Search for deleted files';
  TrashedDocumentSubtitleDescription =
    'Search among the deleted documents of your competence';
  DeleteAllBtnLabel = 'Delete all';
  NoAddedDocumentsMsg = 'No documents have been added at the moment';
  NoTrashedFileMsg = 'There are no Deleted Documents!';
  DocumentsInsertedTitle = 'Documents inserted';

  // VFlow

  HistoryNote = 'Notes';
  HistoryDescription =
    'Here you can view the actions performed by users on the process';
  HistoryTitle = 'Process details';
  RequestStatusEvaso = 'Approved';
  RejectReason = 'Reject reason';
  RejectDialogTitle = 'Enter the reason for the refusal';
  MenuConcludedProcessesLabel = 'Processes concluded';
  RequestStatusELABORAZIONE = 'In processing';
  FormBuilderDropDownListCSVPlaceholder = 'Select csv file';
  FormBuilderDropDownListCSVTitle = 'Drop-down menu with csv data source';
  FormBuilderDropDownListCSVDescription = 'Allows you to import data from csv';
  MessageValidationFileRequestForm = 'Add at least one file';
  ProcessStatus = 'Process status: ';
  Approvers = 'Approved by';
  Deliberators = 'Accepted by';
  Published = 'Published';
  Disabled = 'Disabled';
  // WF view
  TableColumnWFName = 'Name';
  TableColumnWFCategory = 'Category';
  TableColumnWFStato = 'Status';
  TableColumnWFDataCreazione = 'Creation date';
  TableColumnWFCreatore = 'Creator';

  StartRequest = 'Start a request';
  MenuWorkflowLabel = 'WorkFlow manager';
  MenuRequestLabel = 'My requests';
  MenuOngoingProcessesLabel = 'Processes in progress';
  MenuDeliberateProcessesLabel = 'Deliberate processes';

  TitleApp = 'PROCESSES';
  //  Create = 'Create';

  // WorkflowManager Pages
  WorkflowManagerPageTitle = 'Workflows available';
  WorkflowManagerPageCreate = 'CREATE NEW WORKFLOW';

  // Anagrafica
  WorkflowManagerAnagraficaPageTitle = 'Registry';
  NameField = 'Enter a name';
  ProgressiveAlphanumericCodeField = 'Progressive alphanumeric code';
  ProgressiveField = 'Progressive n. of digits';
  ProgressiveTitleTooltip = 'Progressive code';
  ProgressiveDescriptionTooltip =
    'It is composed of alphanumeric coding that is the same for all processes' +
    'and a progressive number of the number of digits indicated in the second field.';
  CreateCategoryField = 'Select or create a category';
  SelectCreateCategory = 'Create new category';
  CategoryNameField = 'Enter the category name';
  PersonalRequestField = 'Personal request';
  PersonalRequestTooltip =
    'If checked, the system makes the applicant a beneficiary.';
  SelectIconField = 'Choose an icon';
  DialogCreateCategoryTitle = 'Create a category';

  // Gruppi
  WorkflowManagerGroupsPageTitle = 'Role-enabled groups';
  WorkflowManagerGroupsRoleTitle = 'Role table';
  WorkflowManagerGroupsNotificationTitle =
    'Notification upon completion of stages';
  TableColumnGroups = 'User group';
  TableColumnTask = 'Job';
  TableColumnActions = 'Actions';
  NotificationSectionTitle = 'Role phase';
  SelectRuoloRField = 'Role R - search user group';
  SelectRuoloCField = 'Role C{index} - search user group';
  SelectRuoloAField = 'Role A - search user group';
  RoleRTooltip = 'User responsible for start a request.';
  RoleCTooltip = 'User approving or requesting a change to the request.';
  RoleATooltip = 'User deliberates positively or negatively the request made.';
  NotificationTooltip =
    'By checking the box, the system notifies the indicated user of the choice made in that phase.';

  // elementi richiesta
  WorkflowManagerRequestelementsPageTitle = 'Request composer';
  SelectElementsField = 'Select a component';
  TableColumnComponentName = 'Component name';
  TableColumnElementName = 'Name of the element';
  TableColumnComponentDescription = 'Component description';
  TableColumnRequired = 'Required';
  RequestElementsToolTip =
    'Field dedicated to choosing the components that will be part of the created request.';

  // Consequences of resolution
  WorkflowManagerConsequencesPageTitle = 'Consequences of resolution';
  DeliberaApprovesField = 'Approval resolution';
  DeliberaDeclineField = 'Resolution of refusal';
  TableColumnDeliberaName = 'Name of resolution';
  TableColumnDeliberaDescription = 'Description of resolution';

  // WF
  WorkflowManagerWFPageTitle = 'Workflow Flow';
  WFMenuTitle = 'Actions';
  WFMenuExportCSV = 'Export workflow to file';
  WFMenuImportVisio = 'Import workflow from Visio';
  WFMenuClear = 'Clear page';

  // Summary
  WorkflowManagerRiepilogoPageTitle = 'Workflow Summary';
  WorkflowManagerRiepilogoNomeWorkflow = 'Workflow Name';
  WorkflowManagerRiepilogoCodificaAlfanumerica = 'Alphanumeric coding';
  WorkflowManagerRiepilogoCategoria = 'Category';
  WorkflowManagerRiepilogoIconaAssociata = 'Associated Icon';
  WorkflowManagerRiepilogoRichiestaPersonali = 'Personal requests';
  WorkflowManagerRiepilogoFaseRuolo = 'Phase Role';
  WorkflowManagerRiepilogoNotifiche =
    'Roles to be informed upon completion of the stage';

  // Deliverable
  WorkflowManagerDeliverablePageTitle = 'Template customization';
  WorkflowManagerDeliverableImportaTemplate = 'Import a template';
  WorkflowManagerDeliverableCreaTemplate = 'Creating Template';
  WorkflowManagerDeliverableEsportaTemplate = 'Export template';
  WorkflowManagerDeliverableContentDefault =
    'DO NOT MODIFY, block content generated by the process';
  WorkflowManagerDeliverableFlussoTitle = 'Workflow Flow';
  WorkflowManagerDeliverableRTETitle = 'Template set';

  AppsPageTitle = 'My apps';
  BeneficiarioPageTitle = 'Request a resolution for the requests made';
  NoAppsMessage = 'There are currently no apps';

  // Request
  SelectCategoryField = 'Select a category';
  SelectStatusField = 'Select a state';
  SelectProcessField = 'Select a process';
  SelectIntervalField = 'Select a range';
  SelectDateField = 'Insert an effective date';
  AmountLabelField = 'Please enter an amount';
  DataRangeLabelField = 'Insert a time range';
  MotivationRequestField = 'Enter the reason for your request here';
  AdditionalNotes =
    'The information entered here will not be added to the resolution document';
  MotivationLabel = 'Motivation of the request';
  NotesLabel = 'Additional Notes';
  SelectBeneficiarioField = 'Select the beneficiary of the request';
  TableColumnBeneficiarioName = 'Beneficiary';
  TableColumnBeneficiarioSede = 'Sede';
  TableColumnBeneficiarioMansione = 'Duties';
  TableColumnBeneficiarioInquadramento = 'Framing';
  RequestPageTitle = 'Resolution information';
  RequestPageSubTitle = 'Compilation required';

  RequestButtonSend = 'Send request';
  RequestButtonSave = 'Save draft';
  RequestButtonDelete = 'Delete request';
  RequestButtonApproves = 'Approve request';
  RequestButtonEdit = 'Request Change';
  RequestButtonAccept = 'Resolution';
  RequestButtonReject = 'Reject';
  SchemeDocumentTitle = 'Resolution document';
  SchemeProcessTitle = 'Progress of the request';
  DeliberaApprovedMessage = 'The process has been approved';
  EditDialogTitle = 'Enter the changes to be made to the process';
  RequestModify = 'Requested changes';
  EmailField = 'Insert a notification email';
  PecField = 'Insert a PEC email';
  DeliberaApprovedSignMessage =
    'The process requires the digital signature of the document to complete the resolution. To continue?';
  // RequestView
  RequestViewPageTitle = 'Filter Results';
  TableColumnRequestProcesso = 'Process';
  TableColumnRequestProgressivo = 'Progressive';
  TableColumnRequestGestione = 'Management';
  TableColumnRequestAzioneDi = 'Action of';
  TableColumnRequestDataRichiesta = 'Requested date';
  TableColumnRequestDataDelibera = 'Data delibera';
  TableColumnRequestRichiedente = 'Requestor';
  RequestStatusBLOCCATA = 'Blocked';
  RequestStatusCOMPLETATA = 'Approved';
  RequestStatusDELIBERA = 'In resolution';
  RequestStatusRIFIUTATO = 'Rejected';
  RequestStatusAPPROVAZIONE = 'In approval';
  RequestStatusMODIFICA = 'In modification';
  RequestStatusBOZZA = 'Draft';
  RequestDigitalSignature = 'Request for signature';

  SaveAndExit = 'Save and exit';
  Remove = 'Remove';
  Roles = 'Roles';
  GoToPage = 'Go to page';
  Allegati = 'Attachments';
  CurrencyCode = 'EUR';
  CurrencySimbol = '€';
  Preview = 'Preview';
  Clear = 'Clean';
  Settings = 'Settings';
  // Preview
  PreviewMotivation = 'Motivation';
  PreviewAmount = 'Amount';
  PreviewDataRangePicker = 'From / To';
  PreviewDatepicker = 'Effective date';
  PreviewDelibera = 'Resolution';
  PreviewReject = 'Reject';

  // Legend
  LegendaTitle = 'Process status legend';
  LegendaColoreVerde = 'Green color';
  LegendaColoreArancione = 'Orange color';
  LegendaColoreRosso = 'Red color';
  LegendaColoreGrigio = 'Gray color';
  LegendaAzioni = 'Request for action by the role';
  LegendaColoreVerdeDescription = 'Action or approval resolution took place';
  LegendaColoreArancioneDescription = 'Change request action occurred';
  LegendaColoreRossoDescription = 'Action or refusal resolution took place';
  LegendaColoreGrigioDescription = 'Action not yet occurred';

  // Form builder
  FormBuilderEmailPlaceholder = 'Insert email to notify';
  FormBuilderEmailTitle = 'Email notification';
  // tslint: disable-next-line: max-line-length
  FormBuilderEmailDescription =
    'You can enter one or more emails separated by; to send the notification relating to the process in progress';

  FormBuilderUploadPlaceholder = 'Attachments';
  FormBuilderUploadDescription =
    'You will be able to upload documents related to the process which will be saved on the cloud';
  FormBuilderUploadTitle = 'File Upload';
  FormBuilderUploadButtonText = 'Upload File';

  FormBuilderTextAreaDescription = 'Alphanumeric text area field';
  FormBuilderTextAreaTitle = 'Text area';
  FormBuilderTextAreaPlaceholder = 'Insert text';

  FormBuilderTextPlaceholder = 'Insert text';
  FormBuilderTextTitle = 'Text area';
  FormBuilderTextDescription = 'Alphanumeric text';

  FormBuilderNumberPlaceholder = 'Please enter a number';
  FormBuilderNumberDescription = 'Numeric field';
  FormBuilderNumberTitle = 'Numeric field';

  FormBuilderDataRangePlaceholder = 'Select a time range';
  FormBuilderDataRangeDescription = 'Selecting a time range';
  FormBuilderDataRangeTitle = 'Calendar - period';

  FormBuilderDataPlaceholder = 'Select a date';
  FormBuilderDataDescription = 'Selecting a specific date';
  FormBuilderDataTitle = 'Calendar - single date';

  FormBuilderCheckboxPlaceholder = 'Choose an option';
  FormBuilderCheckboxTitle = 'Options List';
  FormBuilderCheckboxDescription = 'Allows you to define a list of options';

  // FormBuilderRadioButtonPlaceholder = 'Choose an option';
  // FormBuilderRadioButtonTitle = '';
  // FormBuilderRadioButtonDescription = '';

  FormBuilderDropDownPlaceholder = 'Choose an option';
  FormBuilderDropDownTitle = 'Drop-down menu';
  FormBuilderDropDownDescription = 'Allows you to define a list of options';

  FormBuilderTextEditorPlaceholder = '';
  FormBuilderTextEditorTitle = 'Text Section';
  FormBuilderTextEditorDescription =
    'Allows you to define static text such as paragraphs and headings';

  FormBuilderTextfieldPlaceholder = 'Insert text';
  FormBuilderTextfieldTitle = 'Text';
  FormBuilderTextfieldDescription = 'Text box';

  FormBuilderAutoCompleteUserPlaceholder = 'Search employee';
  FormBuilderAutoCompleteUserTitle = 'Organ with exception';
  FormBuilderAutoCompleteUserDescription =
    'Allows you to search for an employee. (requires LDAP configuration) ';

  FormBuilderGeoMapPlaceholder = 'Enter Location';
  FormBuilderGeoMapTitle = 'Map';
  FormBuilderGeoMapDescription = 'Show the entered location on the map';

  FormBuilderTableTitle = 'Table';
  FormBuilderTableDescription = 'Allows you to define a table for data entry';

  FormBuilderPlaceholder = 'Insert the placeholder text for the field';

  FormBuilderOptionTitle = 'Insert and add + an element';
  FormBuilderOptionPlaceholder = 'Insert an element';

  FormBuilderTimePickerDescription = 'Allows you to select a time';
  FormBuilderTimePickerTitle = 'Time picker';
  FormBuilderTimePickerPlaceholder = 'Select a time';

  FormBuilderOrganoEccezioneOptionTitle = 'Select the role to redefine';
  FormBuilderTableAddColumnButton = 'Add Column';
  FormBuilderTableRowPalceholder = 'Number of rows';
  FormBuilderTableColumnPlaceholder = 'Column title';
  FormBuilderTableColumnTypePlaceholder = 'Type';
  MaxLengthField = 'Maximum number of carraters - unlimited 0';
  ButtonTextField = 'Button Text';
  TypeField = 'Type';
  FormatInputPercentage = 'Percentage';
  FormatInputCurrency = 'Amount';
  FormatInputInteger = 'Integer';
  FormatInputDecimal = 'Decimal';
  AddColumn = 'Add Column';

  DropDownType = 'Drop-down menu';
  CheckBoxType = 'Check';
  DataRangeType = 'Period';
  DataHourType = 'Date and time';
  DataType = 'Date';
  NumberType = 'Numeric';
  TextType = 'Text';

  FormBuilderSidebarComponentTitle = 'Request Items';
  FormBuilderSidebarComponentSubTitle =
    'Enter and define the elements of the request in the desired area';

  // template notification
  TemplateNotification =
    '{DISPLAYNAME} has concluded the {PHASE} phase of the {PROCESS} process for use by {EMPLOYEE}.';
  // tslint: disable-next-line: max-line-length
  TemplateEmail =
    '<div> <p> Hello, </p> <p> {DISPLAYNAME} has concluded the {PHASE} phase of the {PROCESS} process for use by {EMPLOYEE}. </p> <p> Enter vDesk-vFlow to read the details at the following link: <a href=""> {REDIRECTLINK} </a> </p> ';
  TemplateEmailLinkTitle = 'Please login to vDesk to read details';
  TemplateEmailCreationDate = 'Creation date: {CREATIONDATE}';
  TemplateEmailProcessDescription =
    '<i> {DISPLAYNAME} </i> has concluded the <i> {PHASE} </i> phase of the <b> {PROCESS} </b> process for use by <i> {EMPLOYEE} < / i> ';
  TemplateEmailHeader = 'vFlow Notification';
  TemplateEmailHeaderDescription = '';

  TemplateNotificationDigitalsignatures =
    '{DISPLAYNAME} has requested the digital signature for process {PROCESS} for use by {EMPLOYEE}.';
  // tslint: disable-next-line: max-line-length
  TemplateEmailDigitalsignatures =
    '<div> <p> Hi, </p> <p> {DISPLAYNAME} digital signature required for process {PROCESS} for use by {EMPLOYEE}. </p> <p> Enter vDesk-vFlow to read the details at the following link: <a href="~~~~~~~~~~"> {REDIRECTLINK} </a> </p> ';
  TemplateEmailHeaderDescriptionDigitalsignatures = '';
  TemplateEmailLinkTitleDigitalsignatures =
    'Login to vDesk to digitally sign the document';
  TemplateEmailApprovedDateDigitalsignatures =
    'Resolution date: {CREATIONDATE}';
  TemplateEmailProcessDescriptionDigitalsignatures =
    '{DISPLAYNAME} has requested the digital signature for process {PROCESS} for use by {EMPLOYEE}.';
  TemplateEmailHeaderDigitalsignatures = 'vFlow Notification';

  DigitalSignature = 'Digital signature';
  FormBuilderDigitalSignageDescription =
    'Select the roles for which the digital signature of the resolution document is required';
  FormBuilderDigitalSignageTitle = 'Digital signature';
  // dashboard
  RequestMonthApprovedTitleCard = 'Resolutions of the month';
  AllRequestTitleCard = 'Total requests in progress';
  AllRequestApprovedTitleCard = 'Total approved requests';
  PersonalRequestTitleCard = 'Your requests in progress';
  ChartDataPersonalRequestInCorsoTitle = 'Status of your requests';
  ChartDataPersonalRequestDeliberateTitle = 'Your deliberate requests';
  ChartDataRequest = "Status of other people's requests";
  TableColumnHour = 'Hour';
  vFlowActivityCreate = '{USER} has activated the {WFANAME} process';
  vFlowActivityModify = '{USER} requested a change for process {WFANAME}';
  vFlowActivityChanged = '{USER} has modified the {WFANAME} process';
  vFlowActivityApproved = '{USER} has approved the {WFANAME} process';
  vFlowActivityComplete = '{USER} has deliberated the {WFANAME} process';
  vFlowActivityAffectedUser = 'in favor of {USER}';
  // Validation
  TitleValidationForm = 'Form validation';
  MessageValidationCategoriaWfa =
    'Assign a category to proceed in creating the WFA template';
  MessageValidationNomeWfa = 'Define the name of the WFA template to proceed';
  MessageError = 'An error occurred while creating the WFA template';
  TitleErrorForm = 'Error';
  MessageValidationGruppiRuolo =
    'Assign at least one group to each role defined for the WFA template';
  MessageValidationRuolo =
    'Assign at least one role of type R and A to proceed with the creation of the WFA template';
  MessageValidationElementi =
    'Select the elements of the request to proceed with the creation of the WFA template';

  MessageErrorPublishWfa =
    'An error occurred while publishing the WFA template';
  MessageGenericError = 'An error has occurred';
  MessageValidationWF = 'Invalid data';
  MessageValidationAzioni =
    'Define an action for "Required Protocol" and "Required Archiving"';
  MessageUserError =
    'The user data could not be retrieved. Try logging in again ' +
    'and if the error persists contact the administrator';

  MessageApps = 'Sorry no app was found';
  TitleAppsMessage = 'VDesk';

  MessageValidationBeneficiario = 'Indicate the beneficiary of the request';
  MessageRequestError = 'An error occurred while processing your request';
  MessageValidationRequestForm = 'Please fill in all required fields';
  UnauthorizedMessage =
    'You are not authorized to access the app. For more information contact the system administrator ';
  TitleMessageDelibera = 'Practical resolution';
  MessageDelibera = 'The process will be deliberated. To continue?';
  TitleMessageReject = 'Practical reject';
  MessageReject = 'The process will be reject. To continue?';

  MessageValidationEditRequest =
    'Enter the changes to be made before confirming';
  MessageValidationFormBuilderTable = 'Title and column type are required';
  MessageValidationFormBuilderOption = 'Please enter one or more options';
  DeleteRequestMessage =
    'The process will be permanently deleted. Are you sure you want to continue?';
  TitleDeleteRequest = 'Process Deletion';
  MessageDeleteComplete = 'The process has been deleted';
  MessageValidationUsers =
    'The user assigned to approve or deliberate the case cannot be the current user';
  MessageValidationFormBuilderDigitalsign =
    'Select at least one role to request digital signature';
  // tslint: disable-next-line: max-line-length
  MessageValidationGroupsUsers =
    'The indicated user {USER} does not belong to any group selected when creating the workflow. Please indicate another user ';
  MessageOtherSign = 'Document awaiting signature by user {USER}';
  DeliberaRejectMessage = 'The process has been reject';
  TableColumnOrganizationUnit = 'Organization Unit';
  TableColumnFileType = 'File Type';
  TableColumnSignatories = 'Signatories';
  TitleDocumentsSectionTipologyDetail = 'Documents with this type of metadata';
  YourUo = 'Your Organizational Unit';
  AllUo = 'All Organizational Unit';
  TitleDocumentsSectionDossierDetail = 'Documents in this Dossier';

  LoginSubTitle = 'Hi there, welcome to {#APPNAME#}';
  LoginUsernamePassword = 'Insert Username and password to login';
  LoginRememberMe = 'Remember me';
  ForgotPassword = 'I forgot my password';
  LoginEmailForgot =
    'Enter your {#APPNAME#} account email to receive the link and create a new password';
  LoginTempPassword = "You've just logged in with temporary password, create a new one and confirm it";
  LoginTitle = 'Login';
  PasswordRecovery = 'Password recovery';

  DaysLabel = 'Days';
  EmptyUoViewMessage = 'There are no UO yet!';
  EmptyTipologyViewMessage = 'There are no Metadata structure yet!';
  EmptyDossierMessage = 'There are no Dossier yet!';
  EmptyDocumentMessage = 'There are no Documents yet!';
  EmptyProtocolsMessage = 'There are no Protocols yet!';
  EmptyArchivedDocumentsMessage = 'There are no Archived Documents!';
  EmptyUsersMessage = 'There are no Users yet!';
  Protocols = 'Protocols';
  AllProtocols = 'All Protocols';
  Input = 'Input';
  Output = 'Output';
  TableColumnNumber = 'Number';
  TableColumnSource = 'Source';
  ProtocolsArchived = 'Archived Protocols';
  ProtocolsDeleted = 'Deleted Protocols';
  UsersAndRoles = 'Users & Roles';
  TableColumnUserName = 'User name';
  TableColumnUserEmail = 'Email';
  TableColumnInvitationStatus = 'Invitation status';
  TableColumnRole = 'Role';
  AddUserTitle = 'Add User';
  AddUserDescription = 'Add your user';
  EditUserDescription = 'Edit your user';
  Surname = 'Surname';
  Email = 'Email';
  UsernameEmail = 'Username/Email';
  Owner = 'Owner';
  Worker = 'Worker';
  SendInvitation = 'Send Invitation';
  Resend = 'Resend';
  ResendInvitation = 'Resend Invitation';
  Activated = 'Activated';
  Deactivated = 'Deactivated';
  Pending = 'Pending';
  Deactivate = 'Deactivate';
  DeactivatedUserMessage = 'User disabled successfully';
  AddedUserMessage = 'User added successfully';
  UpdatedUserMessage = 'User changed successfully';
  AddToDossierDescription =
    'Select one or more dossiers to add the document to';
  AddToDossierDescriptions =
    'Select one or more dossiers to add the selected documents to';
  SendInvitationMessage = 'Invitation sent successfully';
  PasswordSavedSuccessfully = 'Password saved successfully, please login';
  DescriptionInvitationForm = 'Enter a password for your {#APPNAME#} account';
  NewPassword = 'New password';
  PasswordRequired = 'Password is required';
  PasswordSixCharacters = 'Password should have at least 6 characters';
  ConfirmPassword = 'Confirm Password';
  UsernameRequired = 'Username is required';
  UsernameSixCharacters = 'Username should have at least 3 characters';
  InvalidUsername = 'Not a valid Username';
  InvalidMail = 'Email not valid';
  EncryptionPassword = 'Encryption password';
  Logout = 'Logout';
  Account = 'Account';



  // #region workflow
  YourWorkflow = 'Your Workflow';
  YourWorkflows = 'Your Workflows';
  Workflows = 'Workflows';
  NewWorkflow = 'New Workflow';
  CreateWFButton = 'Create your first Workflow';
  CreateWFTitle = 'Create your Workflow';
  CreateWFPoint1 = '1. Assign a name and a sector';
  CreateWFPoint2 = '2. Define your steps';
  CreateWFPoint3 = '3. Add members and assign them a role.';
  SetYourWF = 'Set your Workflow';
  WFNamePlaceholder = 'Workflow Name';
  WFRelevantSectorPlaceholder = 'Relevant sector';
  WFStepNamePlaceholder = 'Step Name';
  WFUserOrUoPlaceholder = 'User name or UO';
  RequiredAdminMessage = 'Select an Admin in the last step of the Workflow';
  WorkflowDurationPlaceholder = 'Workflow duration';
  Revisor = 'Revisor';
  Approver = 'Approver';
  Publish = 'Publish';
  Save = 'Save';
  Discard = 'Discard';
  WorkflowSavedMessage = 'has been correctly saved and edited.';
  WorkflowPublishedMessage = 'has been correctly saved and published.';
  WorkflowSavedErrorMessage = 'was not saved due to an error.';
  DraftLabel = 'Draft';
  WorkflowDeletedMessage = 'Workflow deleted';
  Trashed = 'Deleted';
  TabArchived = 'Archived'
  // #endregion workflow

  // #region notification
  NotificationEmpty = 'No notifications';
  NotificationTitleNewInvitation = 'New user';
  NotificationNewInvitationFirstPerson =
    'You invited :RecipientDisplayname to join as :Role';
  NotificationNewInvitationThirdPerson =
    ':SenderDisplayname invited :RecipientDisplayname to join as :Role';

  NotificationTitleNewWorkflow = 'New draft flow';
  NotificationNewWorkflowFirstPerson =
    'You have successfully created the draft flow :WorkflowName';
  NotificationNewWorkflowThirdPerson =
    ':WorkflowName was successfully created as a draft flow by :SenderDisplayname';

  NotificationTitleEditWorkflow = 'Edit Flow';
  NotificationEditWorkflowFirstPerson =
    'You have successfully edited the flow in draft state :WorkflowName';
  NotificationEditWorkflowThirdPerson =
    ':WorkflowName has been successfully changed in draft state by :SenderDisplayname';

  NotificationTitlePublishWorkflow = 'New published flow';
  NotificationPublishWorkflowFirstPerson =
    'You have successfully published the flow :WorkflowName';
  NotificationPublishWorkflowThirdPerson =
    ':WorkflowName was successfully published by :SenderDisplayname';

  NotificationTitleNextWorkflow = 'Document Feed';
  NotificationNextWorkflowFirstPerson =
    ':ObjectName is passed to you from step :PreviousStepName of flow :WorkflowName';
  NotificationNextWorkflowThirdPerson =
    ":ObjectName is passed to / all ' :Role :RecipientDisplayname, of step :NextStepName of flow :WorkflowName";

  NotificationTitleBackWorkflow = 'Document demotion';
  NotificationBackWorkflowFirstPerson =
    ':ObjectName is returned to you, from the step :PreviousStepName of the flow :WorkflowName';
  NotificationBackWorkflowThirdPerson =
    ':ObjectName returned to :Role :RecipientDisplayname of step :NextStepName of flow :WorkflowName';

  NotificationTitleArchivedDocument = 'Archived Document';
  NotificationArchivedDocumentFirstPerson =
    'You have successfully archived the document :ObjectName';
  NotificationArchivedDocumentThirdPerson =
    ':ObjectName was successfully archived by :SenderDisplayname';

  NotificationTitleProtocolledDocument = 'Protocolled document';
  NotificationProtocolledDocumentFirstPerson =
    'You have successfully logged the document :ObjectName';
  NotificationProtocolledDocumentThirdPerson =
    ':ObjectName was successfully logged by :SenderDisplayname';

  NotificationTitleRejectedDocument = 'Document rejected';
  NotificationRejectedDocumentFirstPerson =
    'You have successfully rejected the document :ObjectName';
  NotificationRejectedDocumentThirdPerson =
    ':ObjectName was successfully rejected by :SenderDisplayname';

  NotificationTitleDeletedDocument = 'Document deleted';
  NotificationDeletedDocumentFirstPerson =
    'You have deleted the document :ObjectName';
  NotificationDeletedDocumentThirdPerson =
    ':ObjectName has been deleted from :SenderDisplayname';

  NotificationTitleNewDossier = 'New Dossier';
  NotificationNewDossierFirstPerson =
    'You have successfully created the dossier :DossierName';
  NotificationNewDossierThirdPerson =
    'The Dossier :DossierName was successfully created by :SenderDisplayname';

  NotificationTitleUpdateDossier = 'Dossier modified';
  NotificationUpdateDossierFirstPerson =
    'You have successfully edited the dossier :DossierName';
  NotificationUpdateDossierThirdPerson =
    'The Dossier :DossierName has been successfully modified by :SenderDisplayname';

  NotificationTitleArchivedDossier = 'Archived Dossier';
  NotificationArchivedDossierFirstPerson =
    'You have successfully archived the dossier :DossierName';
  NotificationArchivedDossierThirdPerson =
    'The Dossier :DossierName has been successfully archived by :SenderDisplayname';

  NotificationTitleDeletedDossier = 'Dossier deleted';
  NotificationDeletedDossierFirstPerson =
    'You have successfully deleted the dossier :DossierName';
  NotificationDeletedDossierThirdPerson =
    'The Dossier :DossierName has been successfully deleted from :SenderDisplayname';

  NotificationTitleAddedToDossier = 'Collated document';
  NotificationAddedToDossierFirstPerson =
    'You have collated the document :ObjectName in the dossier :DossierName';
  NotificationAddedToDossierThirdPerson =
    ':ObjectName has been collated to :DossierName by :SenderDisplayname';

  NotificationTitleNewUo = 'Organizational Unit';
  NotificationNewUoThirdPerson =
    ':UOName was successfully created by :SenderDisplayname';

  NotificationTitleNewUoUser = 'Organizational Unit';
  NotificationNewUoUserThirdPerson =
    'You have been entered as :Role in Organizational Unit :UOName';

  NotificationTitleDeleteUoUser = 'Organizational Unit';
  NotificationDeleteUoUserThirdPerson =
    'You have been deleted from the OU :UOName';

  NotificationTitleUpdateUo = 'Organizational Unit';
  NotificationUpdateUoThirdPerson =
    ':UOName has been successfully changed by :SenderDisplayname';

  NotificationTitleDeletedUo = 'Organizational Unit';
  NotificationDeletedUoFirstPerson =
    'Your Organizational Unit :UOName has been successfully deleted from :SenderDisplayname';
  NotificationDeletedUoThirdPerson =
    'The Organizational Unit :UOName has been successfully deleted from :SenderDisplayname';

  NotificationTitleEditUoUserRole = 'Organizational Unit';
  NotificationEditUoUserRoleThirdPerson =
    'Your role has been changed to :Role in Organizational Unit :UOName';

  NotificationTitleNewTipology = 'New metadata structure';
  NotificationNewTipologyFirstPerson =
    'You have successfully created the metadata structure :TipologyName';
  NotificationNewTipologyThirdPerson =
    'The Metadata Structure :TipologyName was successfully created by :SenderDisplayname and is available';

  NotificationTitleEditTipology = 'Structure modified';
  NotificationEditTipologyFirstPerson =
    'You have changed the metadata structure :TipologyName';
  NotificationEditTipologyThirdPerson =
    'Metadata Structure :TipologyName has been successfully changed from :SenderDisplayname and is available';

  NotificationTitleDeleteUoTipology = 'Structure modified';
  NotificationDeleteUoTipologyThirdPerson =
    'Metadata Structure :TipologyName has been changed from :SenderDisplayname and is no longer available in organizzation unit :UoName';
  NotificationTitleDeleteTipology = 'Structure deleted';
  NotificationDeleteTipologyFirstPerson =
    'You have successfully deleted the metadata structure :TipologyName';
  NotificationDeleteTipologyThirdPerson =
    'Metadata Structure :TipologyName has been successfully deleted from :SenderDisplayname';

  NotificationTitleUserEdit = 'User modified';
  NotificationUserEditFirstPerson =
    'You have changed user :RecipientDisplayname';
  NotificationUserEditThirdPerson =
    ':SenderDisplayname has changed user :RecipientDisplayname';

  NotificationTitleResetPassword = 'Reset password';
  NotificationResetPasswordFirstPerson =
    'You have reset the password of the user :RecipientDisplayname';
  NotificationResetPasswordThirdPerson =
    ":SenderDisplayname has reset the user's password :RecipientDisplayname";

  NotificationTitleUserDelete = 'User deleted';
  NotificationUserDeleteFirstPerson =
    'You have deleted user :RecipientDisplayname';
  NotificationUserDeleteThirdPerson =
    ':SenderDisplayname has deleted the user :RecipientDisplayname';

  NotificationTitleEditFile = 'Edit document';
  NotificationEditFileFirstPerson = 'You have edited the document :ObjectName';
  NotificationEditFileThirdPerson =
    ':SenderDisplayname has modified the document :ObjectName';

  NotificationTitleCreateFile = 'document added';
  NotificationCreateFileFirstPerson = 'You have added the document :ObjectName';
  NotificationCreateFileThirdPerson =
    ':SenderDisplayname added the document :ObjectName';

  NotificationNextDocumentFirstPerson =
    'You sent :Status the document :ObjectName';
  NotificationNextDocumentThirdPerson =
    ':SenderDisplayname sent :Status the document :ObjectName';

  NotificationConfirmDocumentFirstPerson =
    'You have :Status the document :ObjectName';
  NotificationConfirmDocumentThirdPerson =
    ':SenderDisplayname has :Status the document :ObjectName';

  NotificationTitleCreateFolder = 'Create folder';
  NotificationCreateFolderFirstPerson = 'You have added the folder :ObjectName';
  NotificationCreateFolderThirdPerson =
    ':SenderDisplayname added folder :ObjectName';

  NotificationTitleEditFolder = 'Edit folder';
  NotificationEditFolderFirstPerson = 'You have edited the folder :ObjectName';
  NotificationEditFolderThirdPerson =
    ':SenderDisplayname has changed folder :ObjectName';

  NotificationTitleDeletedFolder = 'Delete folder';
  NotificationDeletedFolderFirstPerson =
    'You have deleted the folder :ObjectName';
  NotificationDeletedFolderThirdPerson =
    ':SenderDisplayname has deleted the folder :ObjectName';

  NotificationRestoreFolderFirstPerson = 'You have restored folder :ObjectName';
  NotificationRestoreDocumentFirstPerson =
    'You have restored the document :ObjectName';
  NotificationRestoreFolderThirdPerson =
    ':SenderDisplayname has restored folder :ObjectName';
  NotificationRestoreDocumentThirdPerson =
    ':SenderDisplayname has restored the document: ObjectName';
  NotificationTitleRestoreFolder = 'Restore Folder';
  NotificationTitleRestoreDocument = 'Restore Document';

  NotificationTitleAddAttachment = 'Attachment uploaded';
  NotificationAddAttachmentThirdPerson =
    ':SenderDisplayname has attached the file :AttachmentName to the document :ObjectName';
  NotificationAddAttachmentFirstPerson =
    'You have attached the file :AttachmentName to the document :ObjectName';

  NotificationTitleDeleteAttachment = 'Attachment deleted';
  NotificationDeleteAttachmentThirdPerson =
    ':SenderDisplayname has deleted the attachment :Document AttachmentName :ObjectName';
  NotificationDeleteAttachmentFirstPerson =
    'You have deleted the attachment :AttachmentName of the document :ObjectName';
  // #endregion notification

  Decrypt = 'Decrypt';
  Encrypt = 'Encrypt';
  EncryptDescription = 'Enter your account password to encrypt the file';
  DecryptDescription = 'Enter your account password to decrypt the file';
  FileNotFound = 'File not found';
  FileDecryptedSuccess = 'File successfully decrypted';
  WrongPassword = 'Wrong password';
  FileEncryptedSuccess = 'File encrypted successfully';
  EditInfoDescription3 = 'Set your encryption password and confirm';
  CurrentPassword = 'Current Password';
  TitleInfo4 = 'Encryption';
  PasswordLength = 'The password must be at least 6 characters long';
  CryptographyDisabled = 'Cryptography Disabled';
  constructor(appname: string = '', displayname: string = '') {
    const properties = Object.getOwnPropertyNames(this);
    for (const property of properties) {
      try {
        this[property] = this[property].replace('{#APPNAME#}', appname);
        this[property] = this[property].replace('{#DISPALYNAME#}', displayname);
      } catch (error) {
        const e = error;
      }
    }
  }
}
