<div class="component-container">
    <span class="material-icons icon-drag  icon-align-center" cdkDragHandle>
        drag_indicator
    </span>
    <mat-form-field appearance="fill" class="form-field">
        <mat-label class="placeholder">{{'Data'}}</mat-label>
        <!-- <input *ngIf="!ReadOnly" matInput class="input-text" [value]='Value' [matDatepicker]="picker"
      (dateInput)="AddEvent('input', $event)" (dateChange)="AddEvent('change', $event)" autocomplete="off"
      [required]="Required" [matDatepickerFilter]="DatepickerFilter"> -->
        <input matInput class="input-text" [matDatepicker]="picker" autocomplete="off" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker">
            <img matDatepickerToggleIcon src="../../../../assets/icon/calendar.svg">
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <span class="material-icons  icon icon-align-center" (click)="DeleteComponent()" >
        delete
    </span>
</div>