<button (click)="print()">print</button><button (click)="Save()">save</button>
<div class="row">
  <div class="col-10">
    <div class="form-builder-container tiny-scrollbar ">
      <div cdkDropList (cdkDropListDropped)="DropRow($event)">
        <div id="{{row?.Id}}" cdkDrag *ngFor="let row of Form?.Rows" class="{{row?.CssClass}} row-list"
          (click)="SelectRow(row)">
          <div class="fieldset">
            <div cdkDragHandle>
              <span class="material-icons icon-drag icon">
                drag_indicator
              </span>
            </div>
            <p>Sezione</p>
            <span class="material-icons-outlined icon" (click)="DeleteRow(row)">
              delete
            </span>

          </div>
          <div class="row-placeholder" *cdkDragPlaceholder></div>
          <div class="columns-list" [ngClass]="RowSelected?.Id==row?.Id?'selected':''">
            <div *ngFor="let column of row?.Columns" class="{{column?.CssClass}} column" id="{{column?.Id}}" cdkDropList
              [cdkDropListData]="column.Components" (cdkDropListDropped)="DropComponent($event,column)"
              cdkDropListConnectedTo="Components">
              <div *ngIf="column?.Components?.length<=0" class="description-empty">
                <p>Trascina qui gli elementi che vuoi inserire</p>
              </div>
              <div *ngFor="let item of column?.Components" cdkDrag [cdkDragData]="item"  >               
                <div class="component-placeholder" *cdkDragPlaceholder></div>
               <app-form-link *ngIf="item.Type==COMPONENTTYPE.LINK" ></app-form-link>
               <app-form-date *ngIf="item.Type==COMPONENTTYPE.DATE" [Data]="{column,item}"></app-form-date>
               <app-form-input *ngIf="item.Type==COMPONENTTYPE.TEXT"  [Type]="'text'"></app-form-input>
               <!-- <app-input *ngIf="item.Type==COMPONENTTYPE.TEXT" [DataValue]='Model?.Dto' [Property]="" [Type]="'text'" [Required]='true'
               [Maxlength]="50" [Placeholder]="'Testo'"
               [Description]="50"></app-input> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <button mat-mini-fab aria-label="Aggiungi sezione" (click)="AddRow()" class="btn-add-row">
        <mat-icon>add</mat-icon>
      </button>

    </div>
  </div>

  <div class="col-2">
    <!-- Layout -->
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)" class="exp-panel">
        <mat-expansion-panel-header>
          <mat-panel-title> Layout </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-4">
            <div class="row layout-widget" (click)="SetLayout(1)" [ngClass]="LayoutSelected==1?'selected':''">
              <div class="col-12 column-widget"></div>
            </div>
            <p class="layout-description">1 colonna</p>
          </div>
          <div class="col-4">
            <div class="row layout-widget" (click)="SetLayout(2)" [ngClass]="LayoutSelected==2?'selected':''">
              <div class="col-6 column-widget"></div>
              <div class="col-6 column-widget"></div>
            </div>
            <p class="layout-description">2 colonne</p>
          </div>
          <div class="col-4">
            <div class="row layout-widget" (click)="SetLayout(3)" [ngClass]="LayoutSelected==3?'selected':''">
              <div class="col-4 column-widget"></div>
              <div class="col-4 column-widget"></div>
              <div class="col-4 column-widget"></div>
            </div>
            <p class="layout-description">3 colonne</p>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- Elementi -->
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)" class="exp-panel">
        <mat-expansion-panel-header>
          <mat-panel-title> Elementi </mat-panel-title>
        </mat-expansion-panel-header>
        <div id="Components" cdkDropList [cdkDropListConnectedTo]="Form?.ColumnsGuids" [cdkDropListData]="Components" cdkDropListSortingDisabled  class="grid-toolbar">
          @for (component of Components; track component;) {
          @if(component?.Section== SECTION.ELEMENT){
          <div class="icon-drag" [cdkDragData]="component" cdkDrag>
            <div class="component-placeholder" *cdkDragPlaceholder></div>
            <img src="../../../../../../assets/icon/form-builder/{{component?.Icon}}" class="icon-drag ">
            <p class="widget-description">{{component?.Description}}</p>
          </div>
          }
          }
        </div>
      </mat-expansion-panel>
    </mat-accordion>
     <!-- Metadati -->
     <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)" class="exp-panel">
        <mat-expansion-panel-header>
          <mat-panel-title> Metadati </mat-panel-title>
        </mat-expansion-panel-header>
        <div id="Components" cdkDropList [cdkDropListConnectedTo]="Form?.ColumnsGuids" [cdkDropListData]="Components" cdkDropListSortingDisabled  class="grid-toolbar">
          @for (component of Components; track component;) {
          @if(component?.Section== SECTION.METADATA){
          <div class="icon-drag" [cdkDragData]="component" cdkDrag>
            <div class="component-placeholder" *cdkDragPlaceholder></div>
            <img src="../../../../../../assets/icon/form-builder/{{component?.Icon}}" class="icon-drag ">
            <p class="widget-description">{{component?.Description}}</p>
          </div>
          }
          }
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div> 
<button mat-mini-fab aria-label="Aggiungi sezione" (click)="Save()" class="btn-add-row">
  <mat-icon>check</mat-icon>
</button>