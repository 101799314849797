import { Component, Inject, OnInit } from '@angular/core';
import { DataService } from '../../../../data.service';
import { NavigatorService } from '../../../../navigator.services';
import { AuthService } from '../../../../auth.service';
import { CommonService } from '../../../../common.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDialogComponent } from '../../../Interface/doc.dialog.interface';
import { ObjectController } from '../../../controllers/ObjectController';
import { ObjectModel } from '../../../models/ObjectModel';



@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit, IDialogComponent<ObjectModel, ObjectController> {
  Model: ObjectModel;
  Controller: ObjectController;
  constructor(
    dataService: DataService, public navigatorService: NavigatorService, public authenticationService: AuthService,
    public commonService: CommonService, public dialogRef: MatDialogRef<TemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
  }

  public Close(){
    this.dialogRef.close();
  }
}
